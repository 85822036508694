import { createSlice } from '@reduxjs/toolkit';
import client from '../../utils/client';

const initialState = {
  videos: null,
  articles: null,
  currentArticle: {},
  currentVideo: null,
  fetchAllSuccess: false,
  fetchAllFailure: false,
  fetchcurrentArticleSuccess: false,
  fetchcurrentArticleFailure: false,
  createVideoSuccess: false,
  createVideoFailure: false,
  createArticleSuccess: false,
  createArticleFailure: false,
  updateVideoSuccess: false,
  updateVideoFailure: false,
  updateArticleSuccess: false,
  updateArticleFailure: false,
  deleteVideoSuccess: false,
  deleteVideoFailure: false,
  deleteArticleSuccess: false,
  deleteArticleFailure: false,
  isLoading: false,
  error: null,
};

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }

  return errorMessage;
};

const slice = createSlice({
  name: 'academy',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setCurrentVideo(state, action) {
      state.currentVideo = action.payload;
    },
    getAllSuccess(state, action) {
      state.fetchAllSuccess = true;
      state.fetchAllFailure = false;
      state.videos = action.payload.videos ? action.payload.videos : [];
      state.articles = action.payload.articles ? action.payload.articles : [];
      state.isLoading = false;
      state.error = null;
    },
    getAllFailure(state, action) {
      state.fetchAllFailure = true;
      state.fetchAllSuccess = false;
      state.videos = [];
      state.articles = [];
      state.isLoading = false;
      state.error = getError(action);
    },

    getArticleSuccess(state, action) {
      state.fetchcurrentArticleSuccess = true;
      state.fetchcurrentArticleFailure = false;
      state.currentArticle = action.payload;
      state.error = null;
      state.isLoading = false;
    },

    getArticleFailure(state, action) {
      state.fetchcurrentArticleFailure = true;
      state.fetchcurrentArticleSuccess = false;
      state.currentArticle = {};
      state.error = getError(action);
      state.isLoading = false;
    },

    postVideoSuccess(state, action) {
      state.createVideoSuccess = true;
      state.createVideoFailure = false;
      state.videos.unshift(action.payload);
      state.isLoading = false;
      state.error = null;
    },
    postVideoFailure(state, action) {
      state.createVideoFailure = true;
      state.createVideoSuccess = false;
      state.isLoading = false;
      state.error = getError(action);
    },
    postArticleSuccess(state, action) {
      state.createArticleSuccess = true;
      state.createArticleFailure = false;
      state.articles.unshift(action.payload);
      // state.currentArticle = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    postArticleFailure(state, action) {
      state.createArticleFailure = true;
      state.createArticleSuccess = false;
      state.currentArticle = null;
      state.isLoading = false;
      state.error = getError(action);
    },
    putVideoSuccess(state, action) {
      state.updateVideoSuccess = true;
      state.updateVideoFailure = false;
      let objIndex = state.videos.findIndex(
        (obj) => obj.video_id == action.payload.video_id
      );
      state.videos[objIndex] = action.payload;
      state.currentVideo = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    putVideoFailure(state, action) {
      state.updateVideoFailure = true;
      state.updateVideoSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },
    putArticleSuccess(state, action) {
      state.updateArticleSuccess = true;
      state.updateArticleFailure = false;
      let objIndex = state.articles.findIndex(
        (article) => article.article_id == action.payload.article_id
      );
      state.currentArticle = action.payload;
      state.articles[objIndex] = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    putArticleFailure(state, action) {
      state.updateArticleFailure = true;
      state.updateArticleSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },
    removeVideoSuccess(state, action) {
      state.deleteVideoSuccess = true;
      state.deleteVideoFailure = false;
      let cVideos = [...state.videos];
      cVideos = cVideos.filter(
        (video) => video.video_id != action.payload.videoId
      );
      state.videos = cVideos;
      state.currentVideo = null;
      state.error = null;
      state.isLoading = false;
    },
    removeVideoFailure(state, action) {
      state.deleteVideoSuccess = false;
      state.deleteVideoFailure = true;
      state.error = getError(action);
      state.isLoading = false;
      state.error = getError(action);
    },
    removeArticleSuccess(state, action) {
      state.deleteArticleSuccess = true;
      state.deleteArticleFailure = false;
      let cArticles = [...state.articles];
      cArticles = cArticles.filter(
        (article) => article.article_id != action.payload.article_id
      );
      state.articles = cArticles;
      state.currentArticle = null;
      state.error = null;
      state.isLoading = false;
    },
    removeArticleFailure(state, action) {
      state.deleteArticleFailure = true;
      state.deleteArticleSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },
    clearStates(state) {
      state.isLoading = false;
      state.error = null;
      state.fetchAllSuccess = false;
      state.fetchAllFailure = false;
      state.createArticleSuccess = false;
      state.createArticleFailure = false;
      state.createVideoSuccess = false;
      state.createVideoFailure = false;
      state.updateVideoSuccess = false;
      state.updateVideoFailure = false;
      state.updateArticleSuccess = false;
      state.updateArticleFailure = false;
      state.deleteVideoSuccess = false;
      state.deleteVideoFailure = false;
      state.deleteArticleSuccess = false;
      state.deleteArticleFailure = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getAll() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get('/academy/get-all')
      .then((response) => {
        dispatch(slice.actions.getAllSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.getAllFailure({ error }));
      });
  };
}

export function getArticle(articleId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/academy/get-article/${articleId}`)
      .then((response) => {
        dispatch(slice.actions.getArticleSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.getArticleFailure({ error }));
      });
  };
}

export function postVideo(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (data.video_thumbnail) {
      file = data.video_thumbnail.data;
      data.video_thumbnail = null;
    }
    client
      .post(`/academy/create-video`, { data, file })
      .then((response) =>
        dispatch(slice.actions.postVideoSuccess(response.data))
      )
      .catch((error) => dispatch(slice.actions.postVideoFailure({ error })));
  };
}

export function postArticle(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (data.article_image) {
      file = data.article_image.data;
      data.article_image = null;
    }
    client
      .post(`/academy/create-article`, { data, file })
      .then((response) =>
        dispatch(slice.actions.postArticleSuccess(response.data))
      )
      .catch((error) => dispatch(slice.actions.postArticleFailure({ error })));
  };
}

export function updateVideo(data, video_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (data.video_thumbnail) {
      file = data.video_thumbnail.data;
      data.video_thumbnail = null;
    }
    client
      .put(`/academy/update-video/${video_id}`, { data, file })
      .then((response) =>
        dispatch(slice.actions.putVideoSuccess(response.data))
      )
      .catch((error) => dispatch(slice.actions.putVideoFailure({ error })));
  };
}

export function updateArticle(data, article_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (data.article_image) {
      file = data.article_image.data;
      data.article_image = null;
    }
    client
      .put(`/academy/update-article/${article_id}`, { data, file })
      .then((response) => {
        dispatch(slice.actions.putArticleSuccess(response.data));
      })
      .catch((error) => dispatch(slice.actions.putArticleFailure({ error })));
  };
}

export function deleteVideo(video_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .del(`/academy/delete-video/${video_id}`)
      .then((response) =>
        dispatch(slice.actions.removeVideoSuccess({ video_id }))
      )
      .catch((error) => dispatch(slice.actions.removeVideoFailure({ error })));
  };
}

export function deleteArticle(article_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .del(`/academy/delete-article/${article_id}`)
      .then((response) =>
        dispatch(slice.actions.removeArticleSuccess({ article_id }))
      )
      .catch((error) =>
        dispatch(slice.actions.removeArticleFailure({ error }))
      );
  };
}

export function clearStates() {
  return async (dispatch) => {
    dispatch(slice.actions.clearStates());
  };
}

export function setVideo(video) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentVideo(video));
  };
}
