import firebase from 'firebase';
const firebaseConfig = {
  apiKey: "AIzaSyCW_vJiXuCfoNSx2DyvHJgKiKU034Fw-5U",
  authDomain: "react-chat-incb.firebaseapp.com",
  databaseURL: "https://react-chat-incb-default-rtdb.firebaseio.com",
  projectId: "react-chat-incb",
  storageBucket: "react-chat-incb.appspot.com",
  messagingSenderId: "1071816443072",
  appId: "1:1071816443072:web:f33f31e296600fd92c1925"
};

firebase.initializeApp(firebaseConfig);
export const firebaseRef = firebase;
export const chatDb = firebase.firestore();

export const cloudinaryConfig = {
  cloudinaryKey: process.env.REACT_APP_CLOUDINARY_KEY,
  cloudinaryPreset: process.env.REACT_APP_CLOUDINARY_PRESET,
  cloudinaryUrl: process.env.REACT_APP_CLOUDINARY_URL,
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

const dev = {
  api: {
    HOST: process.env.REACT_APP_HOST_BACK_END
      ? process.env.REACT_APP_HOST_BACK_END
      : 'http://localhost:8000',
    HOST_FRONT_END: process.env.REACT_APP_HOST_FRONT_END
      ? process.env.REACT_APP_HOST_FRONT_END
      : 'http://localhost:3000',
    VERSION: '/api',
    FLASK_PATH: process.env.REACT_APP_FLASK_PATH
      ? process.env.REACT_APP_FLASK_PATH
      : 'http://localhost:5000',
    // FLASK_PATH:'https://incbuilders.club',
  },
  auth: {
    GRANT_TYPE: 'password',
    CLIENT_ID: 'client1',
    CLIENT_SECRET: 'secret',
  },
  FACEBOOK_APP_ID: 4346588462022132, //Abishek's Test APP
  GOOGLE_CLIENT_ID:
    '804928767168-8jds5o2j1geitppkdmtohtbvujsil86e.apps.googleusercontent.com', //test credentials
  LINKEDIN_CLIENT_ID: '865no927reit4y', // Temporary client ID created by Abishek for development purposes
};

const staging = {
  api: {
    HOST: '',
    VERSION: '/api',
    FLASK_PATH: '',
    HOST_FRONT_END: 'https://backend-971042514732.us-central1.run.app',
  },
  auth: {
    GRANT_TYPE: 'password',
    CLIENT_ID: 'client_id',
    CLIENT_SECRET: 'client_secret',
  },
  GOOGLE_CLIENT_ID:
    '771098364419-qo108nmpl3ldsnlraegliev3lvlj5q5t.apps.googleusercontent.com',
  LINKEDIN_CLIENT_ID: '78benq2lolcksx',
  FACEBOOK_APP_ID: 335610443629188, // INCB FB APP
};

const prod = {
  api: {
    HOST: 'https://incbuilders.biz',
    VERSION: '/api',
    FLASK_PATH: 'https://incbuilders.biz',
    HOST_FRONT_END: 'https://incbuilders.biz',
  },
  auth: {
    GRANT_TYPE: 'password',
    CLIENT_ID: 'client_id',
    CLIENT_SECRET: 'client_secret',
  },
  GOOGLE_CLIENT_ID:
    '771098364419-qo108nmpl3ldsnlraegliev3lvlj5q5t.apps.googleusercontent.com',
  LINKEDIN_CLIENT_ID: '78benq2lolcksx',
  FACEBOOK_APP_ID: 335610443629188, // INCB FB APP
};

let config = dev;

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'staging':
    config = staging;
    break;
  default:
    config = dev;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  SOCIAL_AUTH_PROVIDERS: {
    TWITTER: 'TWITTER',
    LINKEDIN: 'LINKEDIN',
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
  },
  // MAPS_API_KEY: 'AIzaSyB0U2cvdu3pEk_A2mHMPXIEia79pb3G4xs',
  MAPS_API_KEY: 'AIzaSyD0XzqCWYnsOEBZrPJXgODsqa1tobRk7SU',
  
  ...config,
};

// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APPID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };
