import { isEmpty } from 'lodash';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useState } from 'react';
import { useTheme, Typography, Grid } from '@material-ui/core';

export const setProfileStrength = (user, user_startup) => {
  let profileStrength = 0;
  if (!isEmpty(user.user_projects) || !isEmpty(user_startup)) {
    profileStrength += 2;
  }
  if (!isEmpty(user.education)) {
    profileStrength += 1;
  }
  if (!isEmpty(user.experience)) {
    profileStrength += 1;
  }
  if (user.personas.some((persona) => persona.skills)) {
    profileStrength += 1;
  }

  user.profileStrength = profileStrength;
  return user;
};

export const userRedirectUrl = (profileId, currentUserId) => {
  if (profileId == currentUserId) {
    return PATH_DASHBOARD.general.app;
  }
  return `${PATH_DASHBOARD.root}/user/user-page/${profileId}`;
};

export const ReadMore = ({ children, maxLen }) => {
  const theme = useTheme();
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if (!text) return null;

  return (
    <Typography variant='body1'>
      {isReadMore ? text.slice(0, maxLen) : text}
      <Grid
        onClick={toggleReadMore}
        sx={{
          color: theme.palette.info.main,
          cursor: 'pointer',
          display: `${text.length > 150 ? 'initial' : 'none'}`,
          fontWeight: 'bolder',
          ml: 2,
        }}
      >
        {isReadMore ? '...read more' : ' show less'}
      </Grid>
    </Typography>
  );
};
