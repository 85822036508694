import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import {
  AppBar,
  Box,
  Container,
  Hidden,
  Link,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useRef, useState } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// components
import { MIconButton } from '../../components/@material-extend';
import SuccessStories from '../../components/landing-page/SuccessStories';
import Logo from '../../components/Logo';
import MenuPopover from '../../components/MenuPopover';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// routes
import { PATH_AUTH, PATH_COMMUNITY } from '../../routes/paths';
import ThemeSwitcher from '../dashboard/ThemeSwitcher';
// import Drift from 'react-driftjs';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 96;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  '& .isDesktopActive': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .isMobileActive': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows?.z8,
}));

// ----------------------------------------------------------------------

export default function HomeNavbar() {
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);
  const isHome = pathname === '/';
  let MENU_LINKS = [
    { title: 'Login', icon: bookOpenFill, href: PATH_AUTH.login },
    { title: 'Signup', icon: bookOpenFill, href: PATH_AUTH.register },
  ];

  // if (pathname === '/startabusiness') {
  //   MENU_LINKS = [
  //     {
  //       title: 'I want to work with  a business',
  //       icon: homeFill,
  //       href: '/helpabusiness',
  //     },
  //     { title: 'Login', icon: bookOpenFill, href: PATH_AUTH.login },
  //     { title: 'Signup', icon: bookOpenFill, href: PATH_AUTH.register },
  //   ];
  // }
  // if (pathname === '/helpabusiness') {
  //   MENU_LINKS = [
  //     {
  //       title: "I'm a Founder",
  //       icon: homeFill,
  //       href: '/startabusiness',
  //     },
  //     { title: 'Login', icon: bookOpenFill, href: PATH_AUTH.login },
  //     { title: 'Signup', icon: bookOpenFill, href: PATH_AUTH.register },
  //   ];
  // }

  const renderMenuDesktop = (
    <>
      <ThemeSwitcher />
      {MENU_LINKS.map((link) => (
        <Link
          exact
          to={link.href}
          key={link.title}
          underline='none'
          variant='subtitle2'
          component={RouterLink}
          activeClassName='isDesktopActive'
          textOverflow='ellipsis'
          sx={{
            mr: 5,
            color: 'text.primary',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
              }),
            '&:hover': { opacity: 0.8 },
          }}
        >
          {link.title}
        </Link>
      ))}
      <SuccessStories />
    </>
  );

  const renderMenuMobile = (
    <MenuPopover
      disablePortal
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        <ThemeSwitcher />
        {MENU_LINKS.map((link) => (
          <MenuItem
            exact
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName='isMobileActive'
            sx={{ color: 'text.secondary', py: 1 }}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                typography: 'body2',
                noWrap: true,
              }}
            >
              {link.title}
            </ListItemText>
          </MenuItem>
        ))}
        <SuccessStories />
      </List>
    </MenuPopover>
  );

  return (
    <RootStyle color='transparent'>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(offset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 20 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '80%',
          }}
        >
          <RouterLink to='/' style={{ textDecoration: 'none' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Logo />
              &nbsp;&nbsp;
              <Link
                exact
                to='/'
                underline='none'
                variant='h3'
                activeClassName='isDesktopActive'
                sx={{
                  fontWeight: 'bolder',
                  mt: 1,
                  mr: 5,
                  color: 'text.primary',
                  transition: (theme) =>
                    theme.transitions.create('opacity', {
                      duration: theme.transitions.duration.shortest,
                    }),
                  '&:hover': { opacity: 0.6 },
                  // ...(offset && { color: 'text.primary' }),
                  lineHeight: 1,
                }}
              >
                <p>IncBuilders</p>
                <Typography
                  sx={{ p: 0, m: 0, display: { xs: 'none', sm: 'inherit' } }}
                  variant='body2'
                >
                  Social Media for Entrepreneurship
                </Typography>
              </Link>
            </Box>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <Hidden mdDown>{renderMenuDesktop}</Hidden>

          <Hidden mdUp>
            <MIconButton
              ref={anchorRef}
              onClick={() => setOpenMenu(true)}
              sx={{
                ml: 1,
              }}
            >
              <Icon icon={menu2Fill} />
            </MIconButton>
            {renderMenuMobile}
          </Hidden>
        </Container>
      </ToolbarStyle>

      {offset && <ToolbarShadowStyle />}
      {/* {!user && <Drift appId='ma54tkrcpgk4' />} */}
    </RootStyle>
  );
}
