import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { assign, isEmpty, find, remove } from 'lodash';
import { setProfileStrength } from 'src/utils/IncbUtils';
import { v4 as uuidv4 } from 'uuid';
import client from '../../utils/client';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  loginFailed: false,
  loginError: null,
  signupFailed: false,
  signupError: null,
  logoutError: null,
  failureMessage: null,
  flaskLogoutV: false,
  passwordResetMailSuccess: false,
  passwordResetMailFailure: false,
  resetPasswordError: null,
  confirmPasswordSuccess: false,
  confirmPasswordFailure: false,
  confirmPasswordError: null,
  changePasswordSuccess: false,
  changePasswordFailure: false,
  changePasswordError: null,
  updateProfileSuccess: false,
  updateProfileFailure: false,
  updateProfileError: null,
  leaveCommunitySuccess: false,
  leaveCommunityFailure: false,
  leaveCommunityError: null,

  joinCommunitySuccess: false,
  joinCommunityFailure: false,
  joinCommunityError: null,

  communityManagerJoinSuccess: false,
  communityManagerJoinFailure: false,
  communityManagerJoinError: null,
  user: {},
  activeChatId: null,
  publicUser: null,
  FavStartupSuccess: false,
  FavStartupFailure: false,
  viewStartup: null,
};

let refresh_tracker = null;
const refreshDuration = 3000000; // 55 Minutes;
// const refreshDuration = 30000; // 30 seconds

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }
  return errorMessage;
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      if (action.payload.user) {
        state.user = setProfileStrength(action.payload.user);
      } else {
        state.user = action.payload.user;
      }
    },

    loginStart(state) {
      state.flaskLogoutV = false;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = setProfileStrength(action.payload.user);
      state.loginError = null;
      state.loginFailed = false;
      state.flaskLogoutV = false;
      state.isLoading = false;
    },

    loginFailure(state, action) {
      const { error } = action.payload;
      try {
        state.user = setProfileStrength(error.response.data.user);
      } catch {
        state.user = null;
      }
      state.loginFailed = true;
      state.loginError = getError(action, 'Login');
      state.isLoading = false;
      state.isAuthenticated = false;
    },

    signupFailure(state, action) {
      state.isAuthenticated = false;
      state.signupFailed = true;
      state.signupError = getError(action, 'Signup');
      state.isLoading = false;
    },

    // REGISTER
    registerSuccess(state, action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = setProfileStrength(action.payload.user);
      state.flaskLogoutV = false;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },
    flaskLogout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.flaskLogoutV = true;
    },
    logoutFailure(state, action) {
      state.isAuthenticated = false;
      state.user = null;
      state.logoutError = getError(action, 'Logout');
    },

    failure(state, action) {
      state.failureMessage = getError(action);
    },

    personaSelected(state) {
      state.user.persona_selected = true;
    },

    clearLoginError(state) {
      state.loginFailed = false;
      state.loginError = null;
    },

    clearFailureMessage(state) {
      state.failureMessage = null;
    },

    clearSignupError(state) {
      state.signupFailed = false;
      state.signupError = null;
    },

    clearLeaveCommunityError(state) {
      state.leaveCommunityError = null;
      state.leaveCommunityFailure = false;
    },
    clearJoinCommunityError(state) {
      state.joinCommunityError = null;
      state.joinCommunityFailure = false;
      state.joinCommunitySuccess = false;
    },

    resetPasswordSuccess(state) {
      state.passwordResetMailSuccess = true;
      state.passwordResetMailFailure = false;
      state.isLoading = false;
    },

    resetPasswordFailure(state, action) {
      state.passwordResetMailSuccess = false;
      state.passwordResetMailFailure = true;
      state.resetPasswordError = getError(action, 'Password Reset');
      state.isLoading = false;
    },
    clearResetPasswordError(state) {
      state.resetPasswordError = null;
      state.passwordResetMailSuccess = false;
      state.passwordResetMailFailure = false;
    },

    confirmPasswordSuccess(state) {
      state.confirmPasswordSuccess = true;
      state.confirmPasswordFailure = false;
      state.isLoading = false;
    },

    confirmPasswordFailure(state, action) {
      state.confirmPasswordSuccess = false;
      state.confirmPasswordFailure = true;
      state.isLoading = false;
      state.confirmPasswordError = getError(action, 'Confirm Password');
    },

    clearConfirmPasswordError(state) {
      state.confirmPasswordSuccess = false;
      state.confirmPasswordFailure = false;
      state.confirmPasswordError = null;
    },

    changePasswordSuccess(state) {
      state.changePasswordSuccess = true;
      state.changePasswordFailure = false;
      state.isLoading = false;
    },

    changePasswordFailure(state, action) {
      state.changePasswordSuccess = false;
      state.changePasswordFailure = true;
      state.isLoading = false;
      state.changePasswordError = getError(action, 'Change Password');
    },

    clearChangePasswordError(state) {
      state.changePasswordSuccess = false;
      state.changePasswordFailure = false;
      state.changePasswordError = null;
    },

    updateProfileSuccess(state, action) {
      state.user = setProfileStrength(assign(state.user, action.payload.user));
      state.updateProfileSuccess = true;
      state.updateProfileFailure = false;
      state.isLoading = false;
    },

    updateUserStartupsSuccess(state, action) {
      // let user_projects = state.user.user_projects;
      if (
        find(
          state.user.user_projects,
          (u) => u.project_id === action.payload.project_id
        )
      ) {
        remove(state.user.user_projects, {
          project_id: action.payload.project_id,
        });
      }
      state.user.user_projects.push(action.payload);
    },

    updateProfileFailure(state, action) {
      state.updateProfileSuccess = false;
      state.updateProfileFailure = true;
      state.isLoading = false;
      state.updateProfileError = getError(action, 'Update Profile');
    },

    setPublicUser(state, action) {
      state.publicUser = action.payload;
    },

    setUser(state, action) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },

    setActiveChatId(state, action) {
      state.activeChatId = action.payload;
    },

    clearupdateProfileError(state) {
      state.updateProfileSuccess = false;
      state.updateProfileFailure = false;
      state.updateProfileError = null;
    },

    startRefresh() {
      refresh_tracker = setInterval(refresh, refreshDuration);
    },

    stopRefresh() {
      clearInterval(refresh_tracker);
    },

    leaveCommunitySuccess(state, action) {
      state.isLoading = false;
      const { community_id } = action.payload.data;
      state.user.user_communities.other_community = state.user.user_communities.other_community.filter(
        (community) => community.community_id !== community_id
      );
      state.allCommunities = action.payload.communities;
    },

    joinCommunitySuccess(state, action) {
      state.isLoading = false;
      state.joinCommunityFailure = false;
      state.joinCommunitySuccess = true;

      try {
        state.user.user_communities.other_community.push(action.payload);
      } catch (err) {
        if (err.name === 'TypeError') {
          state.user.user_communities.other_community = [action.payload];
        }
      }

      state.allCommunities = action.payload.communities;
    },

    leaveCommunityFailure(state, action) {
      state.isLoading = false;
      state.allCommunities = [];
      state.error = getError(action);
    },

    joinCommunityFailure(state, action) {
      state.isLoading = false;
      state.allCommunities = [];
      state.error = getError(action);
    },

    communityManagerJoinSuccess(state) {
      state.communityManagerJoinSuccess = true;
      state.communityManagerJoinFailure = false;
    },

    communityManagerJoinFailure(state, action) {
      state.communityManagerJoinSuccess = false;
      state.communityManagerJoinFailure = true;
      state.communityManagerJoinError = getError(action);
    },

    clearCommunityManagerJoinRequest(state, action) {
      state.communityManagerJoinSuccess = false;
      state.communityManagerJoinFailure = false;
      state.communityManagerJoinError = null;
    },
    FavouriteStartupSuccess(state, action) {
      state.FavStartupSuccess = true;
      let copiedFavStartup = [...state.user.favourite_startups];
      copiedFavStartup = copiedFavStartup.filter(
        (item) => item.project_id === action.payload.project_id
      );
      if (copiedFavStartup.length > 0) {
        let copiedStartups = [...state.user.favourite_startups];
        copiedStartups = copiedStartups.filter(
          (item) => item.project_id !== action.payload.project_id
        );
        state.user.favourite_startups = copiedStartups;
      } else {
        state.user.favourite_startups.push(action.payload);
      }
      state.FavStartupFailure = false;
      state.isLoading = false;
    },

    FavouriteStartupFailure(state, action) {
      state.FavStartupFailure = true;
      state.FavStartupSuccess = false;
      state.isLoading = false;
    },

    UpdateUserLastQuery(state, action) {
      state.user['last_query'] = action.payload;
    },

    settingViewStartup(state, action) {
      state.viewStartup = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('isAuth', true);
    window.localStorage.setItem('accessToken', accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  if (refreshToken) {
    localStorage.setItem('isAuth', true);
    window.localStorage.setItem('refreshToken', refreshToken);
  }
  if (!accessToken && !refreshToken) {
    localStorage.removeItem('isAuth');
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    // delete axios.defaults.headers.common.Authorization;
  }
};

export function clearLoginError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearLoginError());
  };
}
export function clearResetPasswordError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearResetPasswordError());
  };
}

export function clearConfirmPasswordError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearConfirmPasswordError());
  };
}

export function clearChangePasswordError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearChangePasswordError());
  };
}

export function clearUpdateProfileError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearupdateProfileError());
  };
}

export function clearSignupError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearSignupError());
  };
}

export function clearLeaveCommunityError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearLeaveCommunityError());
  };
}

export function clearJoinCommunityError() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearJoinCommunityError());
  };
}

export function clearFailureMessage() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearFailureMessage());
  };
}

export function clearCommunityManagerJoinRequest() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearCommunityManagerJoinRequest());
  };
}

export function setPublicUser(user) {
  return async (dispatch) => {
    await dispatch(slice.actions.setPublicUser(user));
  };
}

export function setUser(user) {
  return async (dispatch) => {
    await dispatch(slice.actions.setUser(user));
  };
}

export function setActiveChatId(id) {
  return async (dispatch) => {
    await dispatch(slice.actions.setActiveChatId(id));
  };
}

export function updateProfile(userData) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    let file = false;
    if (userData.photo) {
      file = userData.photo;
      userData.photo = null;
    }
    const data = userData;
    client
      .put('/users/update-User', { data, file })
      .then((response) => {
        let { user } = response.data;

        dispatch(slice.actions.updateProfileSuccess({ user }));
      })
      .catch((error) => {
        dispatch(slice.actions.updateProfileFailure({ error }));
      });
  };
}

export function updateUserStartups(startup) {
  return async (dispatch) => {
    dispatch(slice.actions.updateUserStartupsSuccess(startup));
  };
}

export function loadProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    client
      .get('/users/profile')
      .then((response) => {
        const { user } = response.data;
        dispatch(slice.actions.updateProfileSuccess({ user }));
      })
      .catch((error) => {
        dispatch(slice.actions.updateProfileFailure({ error }));
      });
  };
}

export function communityManagerJoinRequest(phoneNumber, name, email) {
  return async (dispatch) => {
    const data = {
      email: email,
      name: name,
      phoneNumber: phoneNumber,
      user_type: 'community_manager',
    };

    client
      .post('/subscribe/create', { data })
      .then((response) => {
        dispatch(slice.actions.communityManagerJoinSuccess());
      })
      .catch((error) => {
        dispatch(slice.actions.communityManagerJoinFailure({ error }));
      });
  };
}

export function login({ email, password }) {
  return async (dispatch) => {
    const data = {
      user_email: email,
      password: password,
    };
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.loginStart());
    client
      .post('/auth/login', { data })
      // .post('/auth/fast_login', { data, useFlask: true })
      .then((response) => {
        const { access_token, user, refresh_token } = response.data;
        setSession(access_token, refresh_token);
        dispatch(slice.actions.startRefresh());
        dispatch(slice.actions.loginSuccess({ user }));
      })
      .catch((error) => {
        dispatch(slice.actions.loginFailure({ error }));
      });
  };
}

export function resetPassword({ email }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post('/users/reset-password?email=' + email)
      .then((response) => {
        dispatch(slice.actions.resetPasswordSuccess());
      })
      .catch((error) => {
        dispatch(slice.actions.resetPasswordFailure({ error }));
      });
  };
}

export function confirmPassword({ token, password }) {
  const data = {
    password: password,
  };
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post('/users/confirm-password?token=' + token, { data })
      .then((response) => {
        dispatch(slice.actions.confirmPasswordSuccess());
      })
      .catch((error) => {
        dispatch(slice.actions.confirmPasswordFailure({ error }));
      });
  };
}

// change password
export function changePassword({ newPassword, oldPassword }) {
  const data = {
    new_password: newPassword,
    old_password: oldPassword,
  };

  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .put('/users/change-password', { data })
      .then((response) => {
        dispatch(slice.actions.changePasswordSuccess());
      })
      .catch((error) => {
        dispatch(slice.actions.changePasswordFailure({ error }));
      });
  };
}

export function social_login({ email, directLogin, oauth_id, oauth_provider }) {
  return async (dispatch) => {
    const data = {
      user_email: email,
      direct_login: directLogin,
      oauth_id: oauth_id,
      oauth_provider: oauth_provider,
    };
    dispatch(slice.actions.startLoading());
    client
      .post('/auth/social_login', { data })
      // .post('/auth/fast_so_login', { data, useFlask: true })
      .then((response) => {
        const { access_token, user, refresh_token } = response.data;
        setSession(access_token, refresh_token);
        dispatch(slice.actions.startRefresh());
        dispatch(slice.actions.loginSuccess({ user }));
      })
      .catch((error) => {
        dispatch(slice.actions.loginFailure({ error }));
      });
  };
}

function refresh(data) {
  return client.post(`/auth/refresh`, { data }).then(
    // assign user id, not the user entity
    (response) => {
      if (response) {
        const { access_token } = response.data;
        setSession(access_token, null);
      }
    },
    (error) => console.log('error refreshing token', error)
  );
}

// ----------------------------------------------------------------------

export function addPersona({ persona, compensation, ftOrPt }) {
  return async (dispatch) => {
    const data = {
      role: persona,
      compensation: compensation,
      ft_or_pt: ftOrPt,
    };

    client
      .post('/users/add-persona', { data })
      .then((response) => {
        dispatch(slice.actions.personaSelected());
      })
      .catch((error) => {
        dispatch(slice.actions.failure({ error }));
      });
  };
}

export function register({
  email,
  password,
  firstName,
  lastName,
  city,
  state,
  country,
  oauth,
  oauth_id,
  oauth_provider,
  persona,
  location,
  marketing_mode
}) {
  return async (dispatch) => {
    let data = {
      user_email: email,
      // self_city: city,
      // self_state: state,
      // self_country: country,
      first_name: firstName,
      last_name: lastName,
      password: password,
      personas: [{ role: persona }],
      user_id: uuidv4(),
      status: false,
      oauth: oauth,
      location: location,
      marketing_mode: marketing_mode,
    };

    if (oauth) {
      data['oauth_id'] = oauth_id;
      data['oauth_provider'] = oauth_provider;
    }

    dispatch(slice.actions.startLoading());
    client
      .post('/users/Users', { data })
      .then((response) => {
        const { access_token, user, refresh_token } = response.data;

        setSession(access_token, refresh_token);
        dispatch(slice.actions.startRefresh());
        dispatch(slice.actions.registerSuccess({ user }));
      })
      .catch((error) => {
        dispatch(slice.actions.signupFailure({ error }));
      });
  };
}

export function clear_redux_user() {
  window.localStorage.removeItem('isAuth');
  window.localStorage.removeItem('socialSigninEmail');
  window.localStorage.removeItem('socialSigninProvider');
  window.localStorage.removeItem('socialSigninError');
  window.localStorage.removeItem('socialSigninStatus');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  return (dispatch) => {
    dispatch(slice.actions.logoutSuccess());
  }
}

export function logout(data) {
  window.localStorage.removeItem('isAuth');
  window.localStorage.removeItem('socialSigninEmail');
  window.localStorage.removeItem('socialSigninProvider');
  window.localStorage.removeItem('socialSigninError');
  window.localStorage.removeItem('socialSigninStatus');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  return async (dispatch) => {
    client.del(`/auth/logout`, { data }).then(
      (payload) => {
        if (payload.data && payload.data.msg) {
          dispatch(slice.actions.stopRefresh());
          setSession(null, null);
          dispatch(slice.actions.logoutSuccess());
        } else {
          console.error('Logout Error');
        }
      },
      (error) => dispatch(slice.actions.logoutFailure(error))
    );
  };
}

export function flask_logout() {
  window.localStorage.removeItem('isAuth');
  window.localStorage.removeItem('socialSigninEmail');
  window.localStorage.removeItem('socialSigninProvider');
  window.localStorage.removeItem('socialSigninError');
  window.localStorage.removeItem('socialSigninStatus');
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('refreshToken');
  return async (dispatch) => {
    client.del(`/auth/flask_logout`).then(
      (payload) => {
        setSession(null, null);
        dispatch(slice.actions.flaskLogout());
        dispatch(slice.actions.stopRefresh());
      },
      (error) => {
        setSession(null, null);
        dispatch(slice.actions.logoutFailure(error));
      }
    );
  };
}

export function leaveCommunity(community, user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const data = {
      community_id: community.community_id,
      user_email: user.user_email,
    };
    client
      .put(`/community/remove-member`, { data })
      .then((response) => {
        dispatch(
          slice.actions.leaveCommunitySuccess({
            data,
          })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.leaveCommunityFailure({ error }));
      });
  };
}

export function joinCommunity(community, user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const data = {
      community_id: community.community_id,
      user_email: user.user_email,
    };
    client
      .put(`/community/add-member`, { data })
      .then((response) => {
        dispatch(slice.actions.joinCommunitySuccess(response.data));
      })
      .catch((error) => {
        console.log('join community failure', error);
        dispatch(slice.actions.joinCommunityFailure({ error }));
      });
  };
}

export function UserFavouriteStartup(project_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post(`/users/add-fav-startup/${project_id}`)
      .then((response) => {
        dispatch(slice.actions.FavouriteStartupSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.FavouriteStartupFailure({ error }));
      });
  };
}

export function UserLastQuery(query) {
  return async (dispatch) => {
    dispatch(slice.actions.UpdateUserLastQuery(query));
  };
}

export function setViewStartup(project) {
  return async (dispatch) => {
    dispatch(slice.actions.settingViewStartup(project));
  };
}

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && isValidToken(refreshToken)) {
        if (!accessToken || !isValidToken(accessToken)) {
          const response = await client.post('/auth/refresh');

          if (response.status === 200) {
            const { access_token } = response.data;
            setSession(access_token, null);
          } else {
            throw 'Refresh Failed';
          }
        }

        const response = await client.get('/users/page_refresh');
        if (response.status === 200 && !isEmpty(response.data.user)) {
          if (response.data.user.confirmed || response.data.user.status) {
            dispatch(
              slice.actions.getInitialize({
                isAuthenticated: true,
                user: response.data.user,
              })
            );
          } else {
            dispatch(
              slice.actions.getInitialize({
                isAuthenticated: false,
                user: response.data.user,
              })
            );
          }

          dispatch(slice.actions.startRefresh());
        } else {
          throw 'Unable to get profile';
        }
      } else {
        throw 'Refresh Token is invalid redirecting to login';
      }
    } catch (error) {
      window.localStorage.removeItem('isAuth');
      window.localStorage.removeItem('socialSigninEmail');
      window.localStorage.removeItem('socialSigninProvider');
      window.localStorage.removeItem('socialSigninError');
      window.localStorage.removeItem('socialSigninStatus');
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('refreshToken');
      console.log(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null,
        })
      );
      if (refresh_tracker) {
        dispatch(slice.actions.stopRefresh());
      }
    }
  };
}
