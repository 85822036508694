import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
import useCommunity from '../hooks/useCommunity';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node,
};

export default function GuestProtect({ children }) {
  const {
    isLoading,
    isAuthenticated,
    user,
    flaskLogoutV,
    passwordResetMailFailure,
    passwordResetMailSuccess,
  } = useAuth();

  const { currentCommunity } = useCommunity();

  let community_id = null;

  // console.log('user_object', user);
  if (!isEmpty(user)) {
    const { home_community } = user.user_communities;
    community_id =
      currentCommunity && currentCommunity.community_id
        ? currentCommunity.community_id
        : home_community.community_id
        ? home_community.community_id
        : user.home_community;
  }

  // if (passwordResetMailFailure || passwordResetMailSuccess) {
  //   return <Redirect to={PATH_AUTH.resetPassword} />;
  // }

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (user && user.user_id && !user.confirmed && !user.status) {
    return <Redirect to={{ pathname: '/request_confirmation', user: user }} />;
  }

  if (
    !flaskLogoutV &&
    isAuthenticated &&
    user &&
    (user.confirmed || user.status)
  ) {
    if (
      !user.persona_selected &&
      (!user.personas || (user.personas && user.personas.length === 0))
    ) {
      return <Redirect to={'/persona_selector'} />;
    }

    if (community_id) {
      return (
        <Redirect to={`${PATH_DASHBOARD.root}/community/${community_id}`} />
      );
    }
  }

  return <>{children}</>;
}
