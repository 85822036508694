import {
  Grid,
  Typography,
  IconButton,
  Button,
  Paper,
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core';
import { NavLink as RouterLink, Redirect, useHistory } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../routes/paths';
import {
  Dashboard,
  Groups,
  Chat,
  Help,
  AccountCircle,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import useCommunity from 'src/hooks/useCommunity';
export default function BottomNavbar() {
  const history = useHistory();
  const theme = useTheme();
  const { currentCommunity } = useCommunity();
  const [page, setPage] = useState(1);
  const [drawer, setDrawer] = useState(false);
  console.log(page, drawer);

  useEffect(() => {
    if (page == 0) {
      history.push(PATH_DASHBOARD.general.app);
    }
    if (page == 1) {
      history.push({
        pathname: `${PATH_DASHBOARD.root}/community/${currentCommunity.community_id}`,
        state: { drawer },
        changeState: () => setDrawer(false),
      });
    }
    if (page == 2) {
      history.push(PATH_DASHBOARD.chat.root);
    }
    if (page == 3) {
      history.push(PATH_DASHBOARD.general.help);
    }
  }, [page, drawer]);

  return (
    <Paper
      container
      item
      component={Grid}
      xs={12}
      sx={{
        position: 'fixed',
        bottom: 0,
        display: { xs: 'inherit', sm: 'none' },
        justifyContent: 'center',
        // height: '30vh',
        zIndex: 10,
        // border: '2px solid red',
        // background: theme.palette.grey[700],
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={page}
        onChange={(event, newValue) => setPage(newValue)}
      >
        <BottomNavigationAction
          label='Dashboard'
          icon={<Dashboard />}
          onClick={() => {
            setDrawer(false);
          }}
        />
        <BottomNavigationAction
          label='Community'
          icon={<Groups />}
          onClick={() => page == 1 && !drawer && setDrawer(true)}
        />
        <BottomNavigationAction
          label='Chat'
          icon={<Chat />}
          onClick={() => setDrawer(false)}
        />
        <BottomNavigationAction
          label='Help'
          icon={<Help />}
          onClick={() => setDrawer(false)}
        />
      </BottomNavigation>
    </Paper>
  );
}
