// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// import {
//   isEmpty,
//   isLoaded,
//   useFirebase,
//   useFirestore
// } from 'react-redux-firebase';
// redux
import {
  addPersona,
  changePassword,
  clearChangePasswordError,
  clearCommunityManagerJoinRequest,
  clearConfirmPasswordError,
  clearFailureMessage,
  clearJoinCommunityError,
  clearLeaveCommunityError,
  clearLoginError,
  clearResetPasswordError,
  clearSignupError,
  clearUpdateProfileError,
  communityManagerJoinRequest,
  confirmPassword,
  flask_logout,
  getInitialize,
  joinCommunity,
  leaveCommunity,
  loadProfile,
  login,
  logout,
  clear_redux_user,
  register,
  resetPassword,
  setPublicUser,
  setUser,
  setActiveChatId,
  social_login,
  updateProfile,
  updateUserStartups,
  UserFavouriteStartup,
  UserLastQuery,
  setViewStartup,
} from '../redux/slices/authJwt';

// ----------------------------------------------------------------------

// useAuth.propTypes = {
//   method: PropTypes.oneOf(['jwt', 'firebase'])
// };

export default function useAuth(method = 'jwt') {
  // // Firebase Auth
  // const firebase = useFirebase();
  // const firestore = useFirestore();
  // const { auth, profile } = useSelector((state) => state.firebase);

  // JWT Auth

  const {
    user,
    activeChatId,
    isLoading,
    isAuthenticated,
    loginFailed,
    loginError,
    signupFailed,
    signupError,
    flaskLogoutV,
    failureMessage,
    passwordResetMailSuccess,
    passwordResetMailFailure,
    resetPasswordError,
    confirmPasswordSuccess,
    confirmPasswordFailure,
    confirmPasswordError,
    changePasswordSuccess,
    changePasswordFailure,
    changePasswordError,
    updateProfileError,
    updateProfileSuccess,
    updateProfileFailure,
    leaveCommunitySuccess,
    leaveCommunityFailure,
    joinCommunitySuccess,
    joinCommunityFailure,
    communityManagerJoinSuccess,
    communityManagerJoinFailure,
    joinCommunitcommunityManagerjoinError,
    publicUser,
    viewStartup,
  } = useSelector((state) => state.authJwt);

  const dispatch = useDispatch();

  // JWT Auth
  if (method === 'jwt') {
    return {
      method: 'jwt',
      user,
      activeChatId,
      isLoading,
      isAuthenticated,
      communityManagerJoinSuccess,
      communityManagerJoinFailure,
      joinCommunitcommunityManagerjoinError,
      signupFailed,
      flaskLogoutV,
      failureMessage,
      passwordResetMailSuccess,
      passwordResetMailFailure,
      resetPasswordError,
      confirmPasswordSuccess,
      confirmPasswordFailure,
      confirmPasswordError,
      changePasswordSuccess,
      changePasswordFailure,
      changePasswordError,
      updateProfileError,
      updateProfileSuccess,
      updateProfileFailure,
      leaveCommunityFailure,
      leaveCommunitySuccess,
      joinCommunitySuccess,
      joinCommunityFailure,
      loginFailed,
      loginError,
      signupError,
      publicUser,
      viewStartup,

      getInitialize: () => dispatch(getInitialize()),

      login: ({ email, password }) =>
        dispatch(
          login({
            email,
            password,
          })
        ),

      updateUserStartups: (startup) => dispatch(updateUserStartups(startup)),

      social_login: ({ email, directLogin, oauth_provider, oauth_id }) =>
        dispatch(
          social_login({
            email,
            directLogin,
            oauth_provider,
            oauth_id,
          })
        ),
      addPersona: ({ persona, compensation, ftOrPt }) =>
        dispatch(
          addPersona({
            persona,
            compensation,
            ftOrPt,
          })
        ),

      register: ({
        email,
        password,
        firstName,
        lastName,
        city,
        state,
        country,
        persona,
        oauth,
        oauth_id,
        oauth_provider,
        location,
        marketing_mode,
      }) =>
        dispatch(
          register({
            email,
            password,
            firstName,
            lastName,
            city,
            state,
            country,
            persona,
            oauth,
            oauth_id,
            oauth_provider,
            location,
            marketing_mode,
          })
        ),

      logout: () => dispatch(logout()),
      clear_redux_user: () => dispatch(clear_redux_user()),
      flask_logout: () => dispatch(flask_logout()),

      clearLoginError: () => dispatch(clearLoginError()),

      clearSignupError: () => dispatch(clearSignupError()),
      clearFailureMessage: () => dispatch(clearFailureMessage()),
      clearResetPasswordError: () => dispatch(clearResetPasswordError()),
      clearConfirmPasswordError: () => dispatch(clearConfirmPasswordError()),
      clearChangePasswordError: () => dispatch(clearChangePasswordError()),
      resetPassword: ({ email }) => {
        dispatch(resetPassword({ email }));
      },

      confirmPassword: ({ token, password }) => {
        dispatch(confirmPassword({ token, password }));
      },

      changePassword: ({ newPassword, oldPassword }) => {
        dispatch(changePassword({ newPassword, oldPassword }));
      },

      updateProfile: (userData) => {
        dispatch(updateProfile(userData));
      },
      loadProfile: () => {
        dispatch(loadProfile());
      },
      clearUpdateProfileError: () => dispatch(clearUpdateProfileError()),
      leaveCommunity: ({ community, user }) =>
        dispatch(leaveCommunity(community, user)),
      clearLeaveCommunityError: () => dispatch(clearLeaveCommunityError()),

      joinCommunity: ({ community, user }) =>
        dispatch(joinCommunity(community, user)),
      clearJoinCommunityError: () => dispatch(clearJoinCommunityError()),
      clearJoinCommunityError: () => dispatch(clearJoinCommunityError()),
      communityManagerJoinRequest: (phoneNUmber, name, email) =>
        dispatch(communityManagerJoinRequest(phoneNUmber, name, email)),
      setPublicUser: (user) => dispatch(setPublicUser(user)),
      setUser: (user) => dispatch(setUser(user)),
      setActiveChatId: (id) => dispatch(setActiveChatId(id)),
      clearCommunityManagerJoinRequest: () =>
        dispatch(clearCommunityManagerJoinRequest()),
      UserFavouriteStartup: (project_id) =>
        dispatch(UserFavouriteStartup(project_id)),
      UserLastQuery: (query) => dispatch(UserLastQuery(query)),

      setViewStartup: (project) => dispatch(setViewStartup(project)),
    };
  }
}
