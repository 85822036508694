import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
// ----------------------------------------------------------------------
import { useTheme } from '@material-ui/core/styles';

Logo.propTypes = {
  height: PropTypes.string,
};
export default function Logo({ height, ...other }) {
  const theme = useTheme();

  let logoSvg =
    theme.palette.mode === 'light'
      ? 'logo_white_theme.svg'
      : 'logo_black_theme.svg';

  if (other.homepage) {
    logoSvg =
      theme.palette.mode === 'light'
        ? 'logo_home_page_white_theme.svg'
        : 'logo_home_page_black_theme.svg';
  }

  return (
    <Box
      component="img"
      alt="logo"
      src={'/static/brand/' + logoSvg}
      height={Number(height) || 60}
      {...other}
    />
  );
}
