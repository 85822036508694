import { Box, Breadcrumbs, Typography } from '@material-ui/core';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { last } from 'lodash';
import PropTypes from 'prop-types';
// material
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled',
    }}
  />
);

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  lineHeight: 2,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': { textDecoration: 'underline' },
  '& > div': { display: 'inherit' },
}));

LinkItem.propTypes = {
  link: PropTypes.object,
};

function LinkItem({ link }) {
  const { href, name, icon } = link;

  return (
    <StyledRouterLink to={href} key={name} variant="body2">
      {icon && (
        <Box
          sx={{
            mr: 1,
            '& svg': { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </StyledRouterLink>
  );
}

MBreadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
  activeLast: PropTypes.bool,
};

function MBreadcrumbs({ links, activeLast = false, ...other }) {
  const currentLink = last(links).name;

  const listDefault = links.map((link) => (
    <LinkItem key={link.name} link={link} />
  ));
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // color: 'text.disabled',
            textOverflow: 'ellipsis',
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
}

export default MBreadcrumbs;
