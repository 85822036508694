import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
// const axiosInstance = axios.create({
//     baseUrl: 'http://localhost:8000'
// });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || error
    )
);

export default axiosInstance;
