// material
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { useState } from 'react';
// import accountSearchOutline from '@iconify-icons/mdi/account-search-outline';
import { DialogAnimate } from '../animate';
// ----------------------------------------------------------------------

const YoutubeEmbed = ({ embedId, title }) => {
  const url = `https://www.youtube.com/embed/${embedId}`;

  return (
    <iframe
      width="100%"
      height="100%"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title={title}
    />
  );
};

VideoDialog.propTypes = {
  buttonName: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  buttonsx: PropTypes.object,
};

export default function VideoDialog({ buttonName, url, title, buttonsx }) {
  const [open, setOpen] = useState(false);
  const buttonstyle = { ...buttonsx };

  return (
    <Box>
      <Button
        variant="subtitle2"
        onClick={() => setOpen(true)}
        style={buttonstyle}
      
      >
        {buttonName}
      </Button>
      <DialogAnimate
        fullWidth
        open={open}
        maxWidth="md"
        scroll="paper"
        onClose={() => setOpen(false)}
      >
        {title && (
          <DialogTitle id="id">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{title}</Box>
              <Box>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
        )}
        <DialogContent>
          <Grid item style={{ height: '60vh' }}>
            <YoutubeEmbed embedId={url} title={title || ''} />
          </Grid>
        </DialogContent>
      </DialogAnimate>
    </Box>
  );
}
