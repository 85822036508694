import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import { Snackbar } from '@material-ui/core';
import axios from 'axios';
import Cookies from 'js-cookie';
import { forEach } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { MIconButton } from '../components/@material-extend';
import config from '../config';

const _ = require('lodash');

const { HOST, VERSION, FLASK_PATH } = config.api;
// const methods = ['get', 'post', 'put', 'patch', 'del'];

export function pathJoin(parts, sep) {
  const separator = sep || '/';
  const replace = new RegExp(separator + '{1,}', 'g');
  return parts.join(separator).replace(replace, separator);
}

export function formatUrl(path, useFlask) {
  let adjustedPath;
  if (process.env.DIST_PREFIX !== undefined) {
    adjustedPath = pathJoin([process.env.DIST_PREFIX, VERSION, path]);
  } else {
    adjustedPath = pathJoin([VERSION, path]);
  }
  if (useFlask) {
    adjustedPath = FLASK_PATH + adjustedPath;
  } else {
    adjustedPath = HOST + adjustedPath;
  }
  return adjustedPath;
}
function performRequest(
  method,
  path,
  { params, data, file, schema, progress, redirects, timeout, useFlask } = {}
) {
  // console.log('method', method);
  // console.log('path', path);
  // console.log('params', params);
  // console.log('client.js data file', data, file);
  let requestData = data;
  let headers = {};
  // if (redirects !== undefined) {
  //   request.redirects(redirects);
  // }
  let withCredentialsValue = true;

  // if (method === 'post' && path === '/users/Users') {
  //   withCredentialsValue = false;
  // }

  if (path === '/auth/refresh') {
    if ('csrf_refresh_token') {
      headers['X-CSRF-Token'] = Cookies.get('csrf_refresh_token');
    }
  } else if (
    localStorage.getItem('isAuth') === 'true' ||
    path === '/auth/logout'
  ) {
    if (Cookies.get('csrf_access_token')) {
      headers['X-CSRF-Token'] = Cookies.get('csrf_access_token');
    }
  }

  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (accessToken && accessToken !== 'undefined' && path !== '/auth/refresh') {
    headers['Authorization'] = 'Bearer ' + accessToken;
  } else if (
    refreshToken &&
    refreshToken !== 'undefined' &&
    path === '/auth/refresh'
  ) {
    headers['Authorization'] = 'Bearer ' + refreshToken;
  }
  // headers['Access-Control-Allow-Origin'] = '*';
  // timeout in milliseconds

  if (file) {
    requestData = new FormData();

    requestData.append('file', file);
    if (data) {
      forEach(data, (field, key) => {
        if (key === 'file') return;
        requestData.append(key, JSON.stringify(field));
      });
    }
    // if (progress) {
    //   request.on('progress', progress);
    // }
    headers['Content-Type'] = 'multipart/form-data';
  } else if (data) {
    headers['Content-Type'] = 'application/json';
  }
  // console.log('Axios  checks-----------');
  // console.log('requestData', requestData);
  // console.log('method', method);
  // console.log('path', path);
  // console.log('url', formatUrl(path, useFlask));
  // console.log('headers', headers);
  // console.log('useFlask', useFlask);
  const response = axios({
    method: method,
    url: formatUrl(path, useFlask),
    data: requestData,
    headers: headers,
    params: params,
    withCredentials: withCredentialsValue,
    timeout: timeout,
  });
  // console.log(response);
  return response;
}

export function HandleFailure({ error } = {}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // A dummy variable to make this a hook
  const [dummy, setDummy] = useState(false);
  const { response } = error;
  // console.log('response', response);

  if (response) {
    if (response.status !== 404) {
      if (
        response &&
        response.statusText &&
        response.body &&
        response.body.detail
      ) {
        return enqueueSnackbar('Login Failure', {
          anchorOrigin: { vertical: 'top', horizontal: 'center' },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
      } else {
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} status="ERROR" />;
      }
    }
    // if (response.status === 401) {
    //   if (
    //     response &&
    //     response.statusText &&
    //     response.body &&
    //     response.body.detail
    //   ) {
    //     // toastr.error(response.statusText, response.body.detail);
    //   } else {
    //     // toastr.error(
    //     //   'Oops!',
    //     //   'you are logged out! try refreshing the app to log back in'
    //     // );
    //   }
    // } else if (response.status === 502) {
    //   // toastr.error('Error!', 'server gateway is down');
    // }
  }
}

export class Client {
  download(path) {
    return new Promise((resolve, reject) => {
      // const data = [];
      // console.log('data', data);
      axios
        .get(formatUrl(path))
        // .on('data', (chunk)=>data.concat(chunk))
        .end((err, response) => {
          if (err) {
            reject(err);
          } else {
            resolve(response.text);
          }
        });
    });
  }
  constructor() {
    this.get = performRequest.bind(this, 'get');
    this.put = performRequest.bind(this, 'put');
    this.post = performRequest.bind(this, 'post');
    this.del = performRequest.bind(this, 'delete');
    this.patch = performRequest.bind(this, 'patch');
  }
}

const client = new Client();

export default client;
