import { Box, Grid, IconButton } from '@material-ui/core';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import useCommunity from 'src/hooks/useCommunity';
import useAuth from '../../hooks/useAuth';
import Navbar from './Navbar';
import { addRecentMessages } from 'src/redux/slices/chat';
import { chatDb, firebaseRef } from 'src/config';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import BottomNavbar from './BottomNavbar';
import React from 'react';
// ----------------------------------------------------------------------

const getIcon = (name) => {
  let src = `/static/icons/navbar/${name}.svg`;
  return (
    <Box
      component='img'
      src={src}
      sx={{
        width: 60,
        height: 60,
      }}
    />
  );
};

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
let BUTTON_WIDTH = 250;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  position: 'relative',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
}));

const ShrinkButton = styled(IconButton)(({ theme }) => ({
  left: APP_BAR_MOBILE,
  right: 0,
  zIndex: 1000,
  position: 'fixed',

  [theme.breakpoints.up('md')]: {
    left: BUTTON_WIDTH,
    top: 10,
  },
}));

// ----------------------------------------------------------------------

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default function DashboardLayout({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [shrink, setShrink] = useState(false);
  const { joinCommunitySuccess, clearJoinCommunityError, user } = useAuth();
  let { currentCommunity, getCommunityDetails, isLoading } = useCommunity();
  let s = window.location.pathname.split('/');
  let url_community = s[s.length - 1];

  useEffect(() => {
    if (
      window.location.pathname.includes('community') &&
      url_community &&
      (!currentCommunity || currentCommunity.community_id !== url_community)
    ) {
      getCommunityDetails({ community_id: url_community });
    } else {
      getCommunityDetails({
        community_id: user.user_communities.home_community.community_id,
      });
    }
  }, []);

  if (joinCommunitySuccess) {
    clearJoinCommunityError();
  }

  const dispatch = useDispatch();
  const chat_members = user ? user.chat_members : [];
  let conversationIds = [];

  const getConversationId = (newUserId) => {
    let conversationId = user.user_id + '--' + newUserId;
    if (user.user_id > newUserId)
      conversationId = newUserId + '--' + user.user_id;
    return conversationId;
  };

  _.forEach(chat_members, (m) =>
    conversationIds.push(getConversationId(m.user_id))
  );
  let recentMessages = [];

  if (_.isEmpty(conversationIds)) conversationIds.push('NOIDS');
  const conversationsRef = chatDb.collection('conversations');
  const recentMsgQuery = conversationsRef.where(
    'members',
    'array-contains',
    user.user_id
  );
  // const recentMsgQuery = conversationsRef.where(firebaseRef.firestore.FieldPath.documentId(), 'in', conversationIds);
  [recentMessages] = useCollectionData(recentMsgQuery, {
    idField: 'conversationId',
  });
  dispatch(addRecentMessages(recentMessages));

  BUTTON_WIDTH = shrink ? 60 : 240;

  // const propsChildren = React.Children.map(children, (child) => {
  //   return React.cloneElement(child, {
  //     message: 'testing this prop',
  //   });
  // });

  return currentCommunity ? (
    <>
      <RootStyle>
        <Navbar
          onOpenSidebar={() => {
            setOpen(true);
            setShrink(false);
          }}
          isShrinkSidebar={shrink}
        />
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <LoadingScreen />
          </Box>
        ) : (
          <Grid container>
            <MainStyle props={'hi'}>{children}</MainStyle>
          </Grid>
        )}

        <BottomNavbar />
      </RootStyle>
    </>
  ) : (
    <LoadingScreen />
  );
}
