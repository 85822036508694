import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import config from '../../config';

class FooterMap extends Component {
  static defaultProps = {
    center: {
      lat: 41.93246077325101,
      lng: -87.69895817180195,
    },
    zoom: 18,
  };
  renderMarkers = (map, maps, center) => {
    let marker = new maps.Marker({
      position: { lat: center.lat, lng: center.lng },
      map,
      title: 'IncBuilders',
    });
    return marker;
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '350px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.MAPS_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            this.renderMarkers(map, maps, this.props.center)
          }
        ></GoogleMapReact>
      </div>
    );
  }
}

export default FooterMap;
