import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authJwtReducer from './slices/authJwt';
import blogReducer from './slices/blog';
import calendarReducer from './slices/calendar';
import chatReducer from './slices/chat';
import communityReducer from './slices/community';
import mailReducer from './slices/mail';
import productReducer from './slices/product';
import settingsReducer from './slices/settings';
import startupsReducer from './slices/startups';
import suimReducer from './slices/suim';
import userReducer from './slices/user';
import reportAbuseReducer from './slices/reportAbuse';
import eventsReducer from './slices/events';
import academyReducer from './slices/academy';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings'],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated', 'user'],
};

const rootReducer = combineReducers({
  // firebase: firebaseReducer,
  firestore: firestoreReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
  product: persistReducer(productPersistConfig, productReducer),
  authJwt: persistReducer(authPersistConfig, authJwtReducer),
  community: communityReducer,
  suim: suimReducer,
  community: communityReducer,
  startup: startupsReducer,
  reportAbuse: reportAbuseReducer,
  events: eventsReducer,
  academy: academyReducer,
});

export { rootPersistConfig, rootReducer };
