import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_AUTH } from '../routes/paths';

// ----------------------------------------------------------------------

AuthProtect.propTypes = {
  children: PropTypes.node,
};

export default function AuthProtect({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  // if (isLoading) {
  //   console.log('im here while hiting the profile dashboard');
  //   return <LoadingScreen />;
  // }

  if (user && user.user_id && !user.confirmed && !user.status) {
    return <Redirect to={'/request_confirmation'} />;
  }

  if (!isAuthenticated) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
