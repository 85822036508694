import { createSlice } from '@reduxjs/toolkit';
import client from '../../utils/client';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  buttonLoading: false,
  currentCommunity: null,
  communityMembers: [],
  communityBlogs: [],
  communityProjects: [],
  communityUpdates: [],
  incbUpdates: [],
  communityPosts: [],
  feedEnd: false,
  error: null,
  allCommunities: [],
  currentTab: 'community',
  announcementError: null,
  createAnnouncementSuccess: false,
  postError: null,
  createPostSuccess: false,
  editPostSuccess: false,
  deletePostSuccess: false,
  communityManagers: [],
  addManagerSuccess: false,
  addManagerFailure: false,
  removeManagerSuccess: false,
  removeManagerFailure: false,
  commentLikeSuccess: false,
  commentLikeFailure: false,
  blog: {},
  fetchBlogSuccess: false,
  fetchBlogFailed: false,
  createBlogSuccess: false,
  createBlogFailed: false,
  updateBlogSuccess: false,
  updateBlogFailed: false,
  deleteBlogSuccess: false,
  deleteBlogFailed: false,
  blogCommentLikesSuccess: false,
  blogCommentLikesFailed: false,
  blogLikesSuccess: false,
  blogLikesFailed: false,
};

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }

  return errorMessage;
};

const slice = createSlice({
  name: 'commmunity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startButtonLoading(state) {
      state.buttonLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = getError(action);
    },

    // GET PROFILE
    getCommunitySuccess(state, action) {
      state.isLoading = false;
      state.currentCommunity = action.payload;
    },

    getCommunityFailure(state, action) {
      state.isLoading = false;
      state.currentCommunity = null;
      state.error = getError(action);
    },

    // GET COMMUNITY MEMBERS
    getCommunityMembersSuccess(state, action) {
      state.isLoading = false;
      state.communityMembers = action.payload.community_members;
      state.currentCommunity = action.payload.community;
    },

    getCommunityMembersFailure(state, action) {
      state.isLoading = false;
      state.communityMembers = [];
      state.currentCommunity = null;
      state.error = getError(action);
    },

    // GET COMMUNITY DETAILS
    getCommunityDetailsSuccess(state, action) {
      state.isLoading = false;
      state.currentCommunity = action.payload.community_info;
      state.communityMembers = action.payload.community_members;
      state.communityBlogs = action.payload.community_blogs;
      state.communityProjects = action.payload.community_projects;
      state.communityUpdates = action.payload.community_updates;
      state.incbUpdates = action.payload.community_incb_updates;
      state.communityManagers = action.payload.community_managers;
      state.communityPosts = action.payload.community_posts;
    },

    getCommunityDetailsFailure(state, action) {
      state.isLoading = false;
      state.currentCommunity = null;
      state.communityMembers = [];
      state.communityBlogs = [];
      state.communityProjects = [];
      state.communityUpdates = [];
      state.incbUpdates = [];
      state.communityPosts = [];
      state.error = getError(action);
    },

    getAllCommunitySuccess(state, action) {
      state.isLoading = false;
      state.allCommunities = action.payload.communities;
    },
    getAllCommunityFailure(state, action) {
      state.isLoading = false;
      state.allCommunities = [];
      state.error = getError(action);
    },

    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    createAnnouncementSuccess(state, action) {
      state.announcementError = null;
      state.createAnnouncementSuccess = true;
      state.incbUpdates.unshift(action.payload);
      state.isLoading = false;
    },

    createAnnouncementFailure(state, action) {
      state.announcementError = getError(action, 'Create Announcement');
      state.createAnnouncementSuccess = false;
      state.isLoading = false;
      // state.error = getError(action);
    },
    createPostSuccess(state, action) {
      state.postError = null;
      state.createPostSuccess = true;
      state.communityPosts.unshift(action.payload);
      state.isLoading = false;
    },

    createPostFailure(state, action) {
      state.postError = getError(action, 'Create Post');
      state.createPostSuccess = false;
      state.isLoading = false;
      // state.error = getError(action);
    },

    editPostSuccess(state, action) {
      state.postError = null;
      state.editPostSuccess = true;
      let objIndex = state.communityPosts.findIndex(
        (obj) => obj.post_id == action.payload.post_id
      );
      state.communityPosts[objIndex] = action.payload;
      state.isLoading = false;
      state.buttonLoading = false;
    },

    editPostFailure(state, action) {
      state.postError = getError(action, 'Edit Post');
      state.editPostSuccess = false;
      state.isLoading = false;
      state.buttonLoading = false;
      // state.error = getError(action);
    },

    deletePostSuccess(state, action) {
      state.postError = null;
      state.deletePostSuccess = true;
      let objIndex = state.communityPosts.findIndex(
        (obj) => obj.post_id == action.payload.post_id
      );
      state.communityPosts.splice(objIndex, 1);
      state.isLoading = false;
    },

    deletePostFailure(state, action) {
      state.postError = getError(action, 'Edit Post');
      state.editPostSuccess = false;
      state.isLoading = false;
      // state.error = getError(action);
    },

    clearAnnoucementResponse(state, action) {
      state.createAnnouncementSuccess = false;
      state.announcementError = null;
    },

    clearPostResponse(state, action) {
      state.createPostSuccess = false;
      state.postError = null;
    },

    clearEditResponse(state, action) {
      state.editPostSuccess = false;
      state.postError = null;
    },

    clearDeleteResponse(state, action) {
      state.deletePostSuccess = false;
      state.postError = null;
    },

    managerAddSuccess(state, action) {
      state.addManagerSuccess = true;
      state.addManagerFailure = false;
      state.communityManagers.push(action.payload);
    },
    managerAddFailure(state, action) {
      state.addManagerSuccess = true;
      state.addManagerFailure = false;
    },
    managerRemoveSuccess(state, action) {
      state.removeManagerSuccess = true;
      state.removeManagerFailure = false;
      let copiedManagers = [...state.communityManagers];
      copiedManagers = copiedManagers.filter(
        (item) => item.user_id !== action.payload
      );
      state.communityManagers = copiedManagers;
    },
    managerRemoveFailure(state, action) {
      state.removeManagerSuccess = true;
      state.removeManagerFailure = false;
    },

    updateCommentLikesSuccess(state, action) {
      state.commentLikeSuccess = true;
      state.commentLikeFailure = false;
      let posts = [...state.communityPosts];

      posts.map((post) => {
        if (post.post_id === action.payload.post_id) {
          post.post_comments.map((comment) => {
            if (comment.comment_id === action.payload.comment_id) {
              if (!comment.comment_likes.includes(action.payload.user_id)) {
                comment.comment_likes.push(action.payload.user_id);
              } else {
                let objIndex = comment.comment_likes.findIndex(
                  (obj) => obj === action.payload.user_id
                );
                comment.comment_likes.splice(objIndex, 1);
              }
            }
          });
        }
      });
      state.communityPosts = posts;
    },

    updateCommentLikesFailure(state, action) {
      state.commentLikeFailure = true;
      state.commentLikeSuccess = false;
    },

    handleLogout(state, action) {
      state.isLoading = false;
      state.currentCommunity = null;
      state.communityMembers = [];
      state.communityBlogs = [];
      state.communityProjects = [];
      state.communityUpdates = [];
      state.communityPosts = [];
      state.incbUpdates = [];
      state.error = null;
      state.allCommunities = [];
      state.currentTab = 'community';
    },

    // Blogs
    // Get blog
    getBlogSuccess(state, action) {
      state.blog = action.payload;
      state.fetchBlogSuccess = true;
      state.fetchBlogFailed = false;
      state.error = null;
      state.isLoading = false;
    },
    getBlogFailure(state, action) {
      state.blog = null;
      state.fetchBlogSuccess = false;
      state.fetchBlogFailed = true;
      state.error = getError(action);
      state.isLoading = false;
    },

    // Post Blog
    postBlogSuccess(state, action) {
      state.blog = action.payload;
      state.communityBlogs.unshift(action.payload);
      state.createBlogSuccess = true;
      state.createBlogFailed = false;
      state.error = null;
      state.isLoading = false;
    },
    postBlogFailure(state, action) {
      state.blog = null;
      state.createBlogFailed = true;
      state.createBlogSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    // Update Blog
    editBlogSuccess(state, action) {
      console.log('this is action payload of comment', action.payload);
      state.blog = action.payload;
      state.updateBlogSuccess = true;
      state.updateBlogFailed = false;
      state.error = null;
      let objIndex = state.communityBlogs.findIndex(
        (obj) => obj.blog_id == action.payload.blog_id
      );
      state.communityBlogs[objIndex] = action.payload;
      state.buttonLoading = false;
      state.isLoading = false;
    },
    editBlogFailure(state, action) {
      state.buttonLoading = false;
      state.updateBlogFailed = true;
      state.updateBlogSuccess = false;
      state.error = getError(action);
      state.blog = action.payload;
      state.isLoading = false;
    },

    // Delete Blog
    removeBlogSuccess(state, action) {
      let blogs = [...state.communityBlogs];
      blogs = blogs.filter((item) => item.blog_id != action.payload.blog_id);
      state.deleteBlogFailed = false;
      state.deleteBlogSuccess = true;
      state.communityBlogs = blogs;
      state.blog = null;
      state.error = null;
      state.isLoading = false;
    },
    removeBlogFailure(state, action) {
      state.deleteBlogSuccess = false;
      state.deleteBlogFailed = true;
      state.error = getError(action);
      state.isLoading = false;
    },

    // Comment Likes
    blogUpdateCommentLikesSuccess(state, action) {
      state.blogCommentLikesSuccess = true;
      state.blogCommentLikesFailed = false;
      // let blogs = [...state.communityBlogs];
      // blogs.map((post) => {
      //   if (post.blog_id === action.payload.data.blog_id) {
      //     post.blog_comments.map((comment) => {
      //       if (comment.comment_id === action.payload.data.comment_id) {
      //         if (
      //           !comment.comment_likes.includes(action.payload.data.user_id)
      //         ) {
      //           comment.comment_likes.push(action.payload.data.user_id);
      //         } else {
      //           let objIndex = comment.comment_likes.findIndex(
      //             (obj) => obj === action.payload.data.user_id
      //           );
      //           comment.comment_likes.splice(objIndex, 1);
      //         }
      //         state.blog = action.payload.blogData;
      //       }
      //     });
      //   }
      // });
      // state.communityBlogs = blogs;

      let cBlog = { ...state.blog };
      cBlog.blog_comments.map((comment) => {
        if (comment.comment_id === action.payload.data.comment_id) {
          if (!comment.comment_likes.includes(action.payload.data.user_id)) {
            comment.comment_likes.push(action.payload.data.user_id);
          } else {
            let objIndex = comment.comment_likes.findIndex(
              (obj) => obj === action.payload.data.user_id
            );
            comment.comment_likes.splice(objIndex, 1);
          }
        }
      });

      state.blog = cBlog;
    },

    blogUpdateCommentLikesFailure(state, action) {
      state.blogCommentLikesFailed = true;
      state.blogCommentLikesSuccess = false;
    },
    clearBlogErrors(state) {
      state.fetchBlogFailed = false;
      state.fetchBlogSuccess = false;
    },

    blogLikesSucceed(state, action) {
      state.blogLikesSuccess = true;
      let lBlog = { ...state.blog };
      if (lBlog.blog_liked_users.includes(action.payload.user_id)) {
        let objIndex = lBlog.blog_liked_users.findIndex(
          (obj) => obj === action.payload.user_id
        );
        lBlog.blog_liked_users.splice(objIndex, 1);
      } else {
        lBlog.blog_liked_users.push(action.payload.user_id);
      }
      state.blog = lBlog;
      state.blogLikesFailed = false;
    },

    updatePaginatedFeed(state, action) {
      console.log(action.payload);
      state.communityPosts = [...state.communityPosts, ...action.payload];
      console.log(state.communityPosts.length);
    },

    feedEnded(state) {
      state.feedEnd = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getCommunityMembers(community) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/community/members/${community.community_id}`)
      .then((response) => {
        const { community_members } = response.data;
        dispatch(
          slice.actions.getCommunityMembersSuccess({
            community_members,
            community,
          })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.getCommunityMembersFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function getCommunityDetails(community) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/community/community/${community.community_id}`)
      .then((response) => {
        const {
          community_members,
          community_blogs,
          community_projects,
          community_updates,
          community_incb_updates,
          community_info,
          community_managers,
          community_posts,
        } = response.data;
        dispatch(
          slice.actions.getCommunityDetailsSuccess({
            community_members,
            community_blogs,
            community_projects,
            community_updates,
            community_incb_updates,
            community_info,
            community_managers,
            community_posts,
          })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.getCommunityDetailsFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function getAllCommunities() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    client
      .get(`/community/`)
      .then((response) => {
        const { communities } = response.data;
        dispatch(
          slice.actions.getAllCommunitySuccess({
            communities,
          })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.getAllCommunityFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function createPost(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (data.post_image) {
      file = data.post_image.data;
      data.post_image = null;
    }

    client
      .post(`/posts/create-post`, { data, file })
      .then((response) => {
        dispatch(slice.actions.createPostSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.createPostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function editPost(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    data.post_image = null;

    client
      .put(`/posts/update-post/${data.post_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.editPostSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editPostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function deletePost(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    client
      .del(`/posts/delete-post/${data.post_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.deletePostSuccess(data));
      })
      .catch((error) => {
        dispatch(slice.actions.deletePostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function createComment(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    let file = false;
    if (data.post_image) {
      file = data.post_image.data;
      data.post_image = null;
    }

    client
      .post(`/posts/${data.post_id}/comment`, { data, file })
      .then((response) => {
        dispatch(slice.actions.editPostSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editPostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function editComment(data) {
  return async (dispatch) => {
    let file = false;
    data.post_image = null;

    client
      .put(`/posts/${data.post_id}/update-comment/${data.comment_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.editPostSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editPostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function deleteComment(data) {
  return async (dispatch) => {
    client
      .del(`/posts/${data.post_id}/delete-comment/${data.comment_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.editPostSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editPostFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function updateLike(data) {
  return async (dispatch) => {
    client
      .put(`/posts/update_likes/${data.post_id}`)
      .then((response) => {
        console.log('Like Success');
      })
      .catch((error) => {
        console.log('Like error');
      });
  };
}

// ----------------------------------------------------------------------

export function updateCommentLikes(data) {
  return async (dispatch) => {
    client
      .put(`/posts/${data.post_id}/comment/${data.comment_id}`)
      .then((response) => {
        dispatch(slice.actions.updateCommentLikesSuccess(data));
      })
      .catch((error) => {
        dispatch(slice.actions.updateCommentLikesFailure(data));
      });
  };
}

// ----------------------------------------------------------------------

export function clearPostResponse() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearPostResponse());
  };
}

// ----------------------------------------------------------------------

export function setCurrentTab(tab) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentTab(tab));
  };
}

// ----------------------------------------------------------------------

export function handleLogout() {
  return async (dispatch) => {
    dispatch(slice.actions.handleLogout());
  };
}

// ----------------------------------------------------------------------

export function createAnnouncement(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post(`/incb-updates/create`, { data })
      .then((response) => {
        dispatch(slice.actions.createAnnouncementSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.createAnnouncementFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function clearAnnoucementResponse() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearAnnoucementResponse());
  };
}

// ----------------------------------------------------------------------

export function managerActions(community_id, manager, operation) {
  return async (dispatch) => {
    const url = `community/${community_id}/add-or-remove-manager/${manager.user_id}`;
    client
      .post(url)
      .then((response) => {
        if (operation == 'remove') {
          dispatch(slice.actions.managerRemoveSuccess(manager.user_id));
        } else if (operation == 'add') {
          dispatch(slice.actions.managerAddSuccess(manager));
        }
      })
      .catch((error) => {
        if (operation == 'remove') {
          dispatch(slice.actions.managerRemoveFailure(error));
        } else if (operation == 'add') {
          dispatch(slice.actions.managerAddFailure(error));
        }
      });
  };
}

// ----------------------------------------------------------------------

export function setBlog(blog) {
  return async (dispatch) => {
    dispatch(slice.actions.getBlogSuccess(blog));
  };
}
// ----------------------------------------------------------------------

export function getBlog(blog_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/blogs/get-blog-by-id/${blog_id}`)
      .then((response) => {
        dispatch(slice.actions.getBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.getBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function createBlog(blog) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (blog.image_link) {
      file = blog.image_link.data;
      blog.image_link = null;
    }
    client
      .post('/blogs/create-blog', { data: blog, file })
      .then((response) => {
        dispatch(slice.actions.postBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.postBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function editBlog(blog) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (blog.image_link) {
      file = blog.image_link.data;
      blog.image_link = null;
    }
    client
      .put(`/blogs/update-blog/${blog.blog_id}`, { data: blog, file })
      .then((response) => {
        dispatch(slice.actions.editBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function deleteBlog(blog_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .del(`/blogs/delete-blog/${blog_id}`)
      .then((response) => {
        dispatch(slice.actions.removeBlogSuccess({ blog_id }));
      })
      .catch((error) => {
        dispatch(slice.actions.removeBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function createBlogComment(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    client
      .post(`/blogs/${data.blog_id}/create-comment`, { data })
      .then((response) => {
        dispatch(slice.actions.editBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function updateBlogComment(data) {
  return async (dispatch) => {
    client
      .put(`/blogs/${data.blog_id}/update-comment/${data.comment_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.editBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function deleteBlogComment(data) {
  return async (dispatch) => {
    client
      .del(`/blogs/${data.blog_id}/delete-comment/${data.comment_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.editBlogSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.editBlogFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function updateBlogLikes(data, user_id) {
  return async (dispatch) => {
    client
      .put(`/blogs/update-likes/${data.blog_id}`)
      .then((response) => {
        dispatch(slice.actions.blogLikesSucceed({ user_id }));
      })
      .catch((error) => {
        console.log('Like error');
      });
  };
}

// ----------------------------------------------------------------------

export function updateBlogCommentLikes(data) {
  return async (dispatch) => {
    client
      .put(`/blogs/${data.blog_id}/comment-like/${data.comment_id}`)
      .then((response) => {
        const blogData = response.data;
        dispatch(
          slice.actions.blogUpdateCommentLikesSuccess({ blogData, data })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.blogUpdateCommentLikesFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function clearBlogError() {
  return async (dispatch) => {
    dispatch(slice.actions.clearBlogErrors());
  };
}

export function updatePaginatedFeed(loadedFeed) {
  return async (dispatch) => {
    dispatch(slice.actions.updatePaginatedFeed(loadedFeed));
  };
}

export function feedEnded() {
  return async (dispatch) => {
    dispatch(slice.actions.feedEnded());
  };
}
