import { createSlice } from '@reduxjs/toolkit';
import client from 'src/utils/client';

const initialState = {
  isLoading: false,
  error: false,
  reportedSuccess: false,
  reportedFailed: false,
  reportError: null,
  fetchReportSuccess: false,
  fetchReportFailed: false,
  fetchReportError: null,
  reportUpdateSuccess: false,
  reportUpdateFailed: false,
  reportUpdateError: null,
  reportApproved: false,
  reportDenied: false,
  report: {},
};

const slice = createSlice({
  name: 'reportAbuse',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReportSuccess(state, action) {
      state.fetchReportSuccess = true;
      state.fetchReportFailed = false;
      state.fetchReportError = null;
      state.report = action.payload;
      state.isLoading = false;
    },
    getReportFailure(state, action) {
      state.isLoading = false;
      state.fetchReportSuccess = false;
      state.fetchReportFailed = true;
      state.fetchReportError = action.payload;
    },
    sendReportSuccess(state, action) {
      state.isLoading = false;
      state.reportedSuccess = true;
      state.reportedFailed = false;
      state.reportError = null;
    },
    sendReportFailed(state, action) {
      state.isLoading = false;
      state.reportedSuccess = false;
      state.reportedFailed = true;
      state.reportError = action.payload;
    },
    updateReportSuccess(state, action) {
      state.isLoading = false;
      state.reportUpdateSuccess = true;
      state.reportUpdateFailed = false;
      state.reportUpdateError = null;
      state.report.view_mode = true;

      if (action.payload == 'Approved') {
        state.report.report_status = 'Approved';
      }
      if (action.payload == 'Denied') {
        state.report.report_status = 'Denied';
      }
    },
    updateReportFailed(state, action) {
      state.isLoading = false;
      state.reportUpdateSuccess = false;
      state.reportUpdateFailed = true;
      state.reportUpdateError = action.payload;
    },
    clearReportActions(state) {
      state.reportedSuccess = false;
      state.reportedFailed = false;
      state.reportError = null;
    },
  },
});
// reducer
export default slice.reducer;

export function clearReportActions() {
  return async (dispatch) => {
    await dispatch(slice.actions.clearReportActions());
  };
}

export function getReport(report_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/reports/get-report-by-id/${report_id}`)
      .then((response) => {
        const report = response.data;
        dispatch(slice.actions.getReportSuccess(report));
      })
      .catch((error) => {
        dispatch(slice.actions.getReportFailure({ error }));
      });
  };
}

export function createReport(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post('/reports/create-report', { data })
      .then((response) => {
        dispatch(slice.actions.sendReportSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.sendReportFailed({ error }));
      });
  };
}

export function updateReport(report_id, report_status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .put(
        `/reports/update-report-status/${report_id}?report_status=${report_status} `
      )
      .then((response) => {
        dispatch(slice.actions.updateReportSuccess(report_status));
      })
      .catch((error) => {
        dispatch(slice.actions.updateReportFailed({ error }));
      });
  };
}
