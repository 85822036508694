import { createSlice } from '@reduxjs/toolkit';
import { project } from 'deck.gl';
import client from '../../utils/client';
import _ from 'lodash';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myStartups: [],
  selectedStartup: null,
  createStartupSuccess: false,
  createStartupFailure: false,
  publicStartup: null,
  matchingStartup: [],
  matchingData: {
    advisor: [],
    coreTeam: [],
    student: [],
    entrepreneur: [],
  },
  getMatchingStartupSuccess: false,
  getMatchingStartupFailure: false,
  getAdvisorSuccess: false,
  getAdvisorFailure: false,
  getCoreTeamSuccess: false,
  getCoreTeamFailure: false,
  favouriteUserSuccess: false,
  favouriteUserFailure: false,
  updayeStartupSuccess: false,
  updayeStartupFailure: false,
  startupJourney: [],
  addJourneySuccess: false,
  addJourneyFailure: false,
  updateJourneySuccess: false,
  updateJourneyFailure: false,
  deleteJourneySuccess: false,
  deleteJourneyFailure: false,
  currentAdvisor: null,
  setCurrentAdvisorSuccess: false,
  setCurrentAdvisorFailure: false,
};

const urls = {
  advisor: 'matchAdvisors',
  coreTeam: 'matchUsers',
};

const findStartupUrls = {
  advisor: 'matchAdvisorsToProjects',
};

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }

  return errorMessage;
};

const slice = createSlice({
  name: 'startup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = getError(action);
    },

    // GET STARTUPS
    getStartupSuccess(state, action) {
      state.isLoading = false;
      state.myStartups = action.payload;
      state.error = getError(action);
    },

    // GET Selected STARTUP
    getSelectedStartupSuccess(state, action) {
      state.isLoading = false;
      state.selectedStartup = action.payload;
      state.startupJourney = action.payload.startup_journey;
      state.error = getError(action);
    },

    createStartupSuccess(state, action) {
      state.selectedStartup = action.payload;
      state.createStartupSuccess = true;
      state.createStartupFailure = false;
      state.isLoading = false;
    },

    createStartupFailure(state, action) {
      state.createStartupSuccess = false;
      state.createStartupFailure = true;
      state.isLoading = false;
      // state.updateProfileError = getError(action, 'Update Profile');
    },

    updateStartupSuccess(state, action) {
      state.selectedStartup = action.payload;
      state.updayeStartupSuccess = true;
      state.updayeStartupFailure = false;
      state.isLoading = false;
    },

    updateStartupFailure(state, action) {
      state.updayeStartupSuccess = false;
      state.updayeStartupFailure = true;
      state.isLoading = false;
      // state.updateProfileError = getError(action, 'Update Profile');
    },

    // clearCreateStartupError(state) {
    //   state.createStartupSuccess = false;
    //   state.updateProfileFailure = false;
    //   state.updateProfileError = null;
    // },

    setPublicStartup(state, action) {
      state.publicStartup = action.payload;
    },

    advisorSuccess(state, action) {
      state.getAdvisorSuccess = true;
      state.matchingData.advisor = action.payload;
      state.getAdvisorFailure = false;
      state.isLoading = false;
    },
    advisorFailure(state, action) {
      state.getAdvisorSuccess = false;
      state.matchingData.advisor = null;
      state.getAdvisorFailure = true;
      state.error = getError(action);
      state.isLoading = false;
    },

    coreTeamSuccess(state, action) {
      state.getCoreTeamSuccess = true;
      state.getCoreTeamFailure = false;
      state.matchingData.coreTeam = action.payload;
      state.error = null;
      state.isLoading = false;
    },

    coreTeamFailure(state, action) {
      state.getCoreTeamFailure = true;
      state.matchingData.coreTeam = null;
      state.getCoreTeamSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    matchingStartupSuccess(state, action) {
      state.getMatchingStartupSuccess = true;
      state.matchingStartup = action.payload;
      state.getMatchingStartupFailure = false;
      state.isLoading = false;
    },
    matchingStartupFailed(state, action) {
      state.getMatchingStartupFailure = true;
      state.matchingStartup = null;
      state.error = getError(action);
      state.getMatchingStartupSuccess = false;
      state.isLoading = false;
    },
    userFavouriteSuccess(state, action) {
      state.favouriteUserSuccess = true;
      state.favouriteUserFailure = false;
      if (state.selectedStartup.favourite_users !== null) {
        let fav_users = { ...state.selectedStartup.favourite_users };

        if (action.payload.action === 'Added') {
          if (_.isUndefined(fav_users[action.payload.persona])) {
            fav_users[action.payload.persona] = [];
          }
          fav_users[action.payload.persona].unshift(action.payload);
        } else if (action.payload.action === 'Removed') {
          fav_users[action.payload.persona] = fav_users[
            action.payload.persona
          ].filter((user) => user.user_id !== action.payload.user_id);
        }

        state.selectedStartup.favourite_users = fav_users;
      } else {
        let fav_users = {};
        fav_users[action.payload.persona] = [action.payload];
        state.selectedStartup.favourite_users = fav_users;
      }
      state.currentAdvisor = null;
      state.isLoading = false;
    },
    userFavouriteFailure(state, action) {
      state.favouriteUserSuccess = false;
      state.favouriteUserFailure = true;
      state.isLoading = false;
    },
    startupUpdateLastQuery(state, action) {
      state.selectedStartup.last_query[action.payload.persona] =
        action.payload.query;
    },

    startupJourneySuccess(state, action) {
      state.startupJourney = action.payload.data;
      if (action.payload.action === 'post') {
        state.addJourneySuccess = true;
        state.addJourneyFailure = false;
      } else if (action.payload.action === 'update') {
        state.updateJourneySuccess = true;
        state.updateJourneyFailure = false;
      } else if (action.payload.action === 'del') {
        state.deleteJourneySuccess = true;
        state.deleteJourneyFailure = false;
      }
      state.isLoading = false;
    },
    startupJourneyFailed(state, action) {
      // state.startupJourney = action.payload
      if (action.payload.action === 'post') {
        state.addJourneySuccess = false;
        state.addJourneyFailure = true;
      } else if (action.payload.action === 'update') {
        state.updateJourneySuccess = false;
        state.updateJourneyFailure = true;
      } else if (action.payload.action === 'del') {
        state.deleteJourneySuccess = false;
        state.deleteJourneyFailure = true;
      }
      state.error = getError(action);
      state.isLoading = false;
    },

    setCurrentAdvisor(state, action) {
      state.currentAdvisor = action.payload;
      state.setCurrentAdvisorSuccess = true;
      state.setCurrentAdvisorFailure = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getStartups() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/projects/get_projects`)
      .then((response) => {
        const startups = response.data;
        dispatch(slice.actions.getStartupSuccess({ startups }));
      })
      .catch((error) => {
        dispatch(slice.actions.hasError({ error }));
      });
  };
}

export function getStartup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/projects/${id}`)
      .then((response) => {
        const startup = response.data;
        dispatch(slice.actions.getSelectedStartupSuccess(startup));
      })
      .catch((error) => {
        dispatch(slice.actions.hasError({ error }));
      });
  };
}

export function createStartup(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post(`/projects/create-startup`, { data })
      .then((response) => {
        const startup = response.data;
        dispatch(slice.actions.getSelectedStartupSuccess(startup));
      })
      .catch((error) => {
        dispatch(slice.actions.hasError({ error }));
      });
  };
}

export function updateStartup(startupData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let file = false;
    if (startupData.profile_photo) {
      file = startupData.profile_photo;
      startupData.profile_photo = null;
    }
    const data = startupData;
    client
      .put('/projects/update', { data, file })
      .then((response) => {
        const startup = response.data;
        dispatch(slice.actions.updateStartupSuccess(startup));
      })
      .catch((error) => {
        dispatch(slice.actions.updateStartupFailure({ error }));
      });
  };
}

export function setPublicStartup(startup) {
  return async (dispatch) => {
    await dispatch(slice.actions.setPublicStartup(startup));
  };
}

export function getMatchingAdvisors(project_id, persona, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const url = `/search/${urls[persona]}?project_id=${project_id}`;
    client
      .post(url, { data })
      .then((response) => {
        const { results } = response.data;
        if (persona == 'advisor')
          dispatch(slice.actions.advisorSuccess(results));
        else if (persona == 'coreTeam')
          dispatch(slice.actions.coreTeamSuccess(results));
      })
      .catch((error) => {
        if (persona == 'advisor')
          dispatch(slice.actions.advisorFailure({ error }));
        else if (persona == 'coreTeam')
          dispatch(slice.actions.coreTeamFailure({ error }));
      });
  };
}

export function getMatchingStartup(persona, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const url = `/search/${findStartupUrls[persona]}`;
    client
      .post(url, { data })
      .then((response) => {
        const { results } = response.data;
        dispatch(slice.actions.matchingStartupSuccess(results));
      })
      .catch((error) => {
        dispatch(slice.actions.matchingStartupFailed({ error }));
      });
  };
}

export function StartupFavourites(project_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post(`/projects/project/${project_id}/favourite-users`, { data })
      .then((response) => {
        response.data['persona'] = data['user_type'];
        dispatch(slice.actions.userFavouriteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.userFavouriteFailure({ error }));
      });
  };
}

export function UpdateLastQuery(persona, query) {
  return async (dispatch) => {
    dispatch(slice.actions.startupUpdateLastQuery({ persona, query }));
  };
}

export function postTimeline(project_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const action = 'post';
    client
      .post(`/projects/project/${project_id}/add-journey`, { data })
      .then((response) => {
        const data = response.data;
        dispatch(slice.actions.startupJourneySuccess({ data, action }));
      })
      .catch((error) => {
        dispatch(slice.actions.startupJourneyFailed({ error, action }));
      });
  };
}

export function updateTimeline(project_id, journey_id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const action = 'update';
    client
      .put(`/projects/project/${project_id}/update-journey/${journey_id}`, {
        data,
      })
      .then((response) => {
        const data = response.data;
        dispatch(slice.actions.startupJourneySuccess({ data, action }));
      })
      .catch((error) => {
        dispatch(slice.actions.startupJourneyFailed({ error, action }));
      });
  };
}

export function deleteTimeline(project_id, journey_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const action = 'del';
    client
      .del(`/projects/project/${project_id}/delete-journey/${journey_id}`)
      .then((response) => {
        const data = response.data;
        dispatch(slice.actions.startupJourneySuccess({ data, action }));
      })
      .catch((error) => {
        dispatch(slice.actions.startupJourneyFailed({ error, action }));
      });
  };
}

export function setAdvisor(advisor) {
  return async (dispatch) => {
    dispatch(slice.actions.setCurrentAdvisor(advisor));
  };
}
