// material
import {
  Box,
  Button,
  Card,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { find } from 'lodash';
import { useState } from 'react';
// import accountSearchOutline from '@iconify-icons/mdi/account-search-outline';
import { useLocation } from 'react-router-dom';
import { DialogAnimate } from '../animate';

// ----------------------------------------------------------------------

const YT_URLS = [
  {
    title: 'Funily - How IncB helped them launch',
    id: 'O6-Jka9bcTE',
    desc:
      'Rich joined reluctantly an IncBuilders event and got selected for help from University of Illinois. With his co-founder Mike and four students from the university, they were able to launch their app in 4 months.',
  },
  {
    title: 'Shelf-Life Chef - Idea to product',
    id: 'gP8V9_HEQfc',
    desc:
      "All Saleem had was an idea in his head. He came to IncBuilders and figured out next steps to create an app that helps you suggest recipes based on what's in your pantry/shelf. In the process he also found, Tristan, a developer to help his technology through IncBuilders.",
  },
];

const YoutubeEmbed = ({ embedId }) => {
  const url = `https://www.youtube.com/embed/${embedId}`;

  return (
    <iframe
      width="100%"
      height="100%"
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};

export default function SuccessStories() {
  const [open, setOpen] = useState(false);
  const [ytId, setYtId] = useState(YT_URLS[0]['id']);
  const { pathname } = useLocation();

  return (
    <Box>
      <Button variant="subtitle2" onClick={() => setOpen(true)}>
        Success Stories
      </Button>
      <DialogAnimate
        fullWidth
        open={open}
        maxWidth="md"
        scroll="paper"
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Success Stories</Box>
            <Box>
              <IconButton onClick={() => setOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px',
                  maxHeight: '250px',
                }}
              >
                <List>
                  {YT_URLS.map((link) => (
                    <ListItem
                      button
                      key={link.title}
                      onClick={() => setYtId(link.id)}
                      selected={link.id === ytId}
                    >
                      <ListItemText
                        primaryTypographyProps={{ typography: 'body2' }}
                      >
                        {link.title}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Card>
              <Card
                sx={{ minHeight: '200px', maxHeight: '300px', padding: '10px' }}
              >
                {find(YT_URLS, (url) => url.id === ytId).desc}
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <YoutubeEmbed embedId={ytId} />
            </Grid>
          </Grid>
        </DialogContent>
      </DialogAnimate>
    </Box>
  );
}
