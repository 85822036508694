import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import client from '../../utils/client';

// ----------------------------------------------------------------------
/*
This slice is used when user adds a new University, Skill, Major or industry
*/

const initialState = {
  isLoading: false,
  addingSuccess: false,
  error: null,
  industries: [],
  universities: [],
  skills: [],
  majors: [],
};

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }

  return errorMessage;
};

const slice = createSlice({
  name: 'suim',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = getError(action);
    },

    // GET PROFILE
    success(state, action) {
      state.isLoading = false;
      state.addingSuccess = true;
      const { skills, universities, industries, majors } = action.payload;
      if (!isEmpty(skills)) {
        state.skills = skills;
      }
      if (!isEmpty(universities)) {
        state.universities = universities;
      }
      if (!isEmpty(industries)) {
        state.industries = industries;
      }
      if (!isEmpty(majors)) {
        state.majors = majors;
      }
    },

    failure(state, action) {
      state.isLoading = false;
      state.addingSuccess = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function addData(newData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const data = {
      new_data: newData,
    };
    client
      .post(`/users/update_suim`, { data })
      .then((response) => {
        dispatch(slice.actions.success(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.failure());
      });
  };
}

export function loadSUIM(fields) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    console.log('this is fields', fields);
    client
      .get(`/users/get_suim?fields=${fields}`)
      .then((response) => {
        dispatch(slice.actions.success(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.failure());
      });
  };
}
