import { createSlice } from '@reduxjs/toolkit';
import client from '../../utils/client';

const initialState = {
  communityEvents: [],
  joinedEvents: [],
  fetchEventSuccess: false,
  fetchEventFailure: false,
  createEventSuccess: false,
  createEventFailure: false,
  updateEventSuccess: false,
  updateEventFailure: false,
  deleteEventSuccess: false,
  deleteEventFailure: false,
  confirmedAttendeeSuccess: false,
  confirmedAttendeesFailure: false,
  nonAttendeeSuccess: false,
  nonAttendeeFailure: false,
  mightAttendeeSuccess: false,
  mightAttendeeFailure: false,
  error: null,
  isLoading: false,
  buttonLoading: false,
  currentEvent: {},
  getCurrentEventSuccess: false,
  getCurrentEventFailure: false,
  getJoinedEventSuccess: false,
  getJoinedEventFailure: false,
  emailInviteSuccess: false,
  emailInviteFailure: false,
};

const getError = (error, operation) => {
  let errorMessage = 'Something Went wrong with ' + operation ? operation : '';
  let response = null;
  if (error.payload.error) {
    response = error.payload.error.response;
  } else if (error.payload.response) {
    response = error.payload.response;
  }

  if (response) {
    const { message, detail } = response.data;
    if (message) {
      return message;
    } else if (detail) {
      return detail;
    } else {
      return response.statusText + ' ' + errorMessage;
    }
  }

  return errorMessage;
};

const slice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startButtonLoading(state) {
      state.buttonLoading = true;
    },

    // Events
    getEventSuccess(state, action) {
      state.fetchEventSuccess = true;
      state.fetchEventFailure = false;
      state.communityEvents = action.payload ? action.payload : [];
      state.isLoading = false;
      state.error = null;
    },
    getEventFailure(state, action) {
      state.fetchEventFailure = true;
      state.fetchEventSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    postEventSuccess(state, action) {
      state.createEventFailure = false;
      state.createEventSuccess = true;
      state.communityEvents.unshift(action.payload);
      state.error = null;
      state.isLoading = false;
    },
    postEventFailure(state, action) {
      state.createEventFailure = true;
      state.createEventSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    putEventSuccess(state, action) {
      state.updateEventFailure = false;
      state.updateEventSuccess = true;
      // let cEvents = [...state.communityEvents];

      // cEvents.forEach((events) => {
      //   if (events.event_id === action.payload.event_id) {
      //     events = action.payload;
      //   }
      // });
      let objIndex = state.communityEvents.findIndex(
        (event) => event.event_id == action.payload.event_id
      );
      state.communityEvents[objIndex] = action.payload;
      state.currentEvent = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    putEventFailure(state, action) {
      state.updateEventFailure = true;
      state.updateEventSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    removeEventSuccess(state, action) {
      state.deleteEventFailure = false;
      state.deleteEventSuccess = true;
      state.error = null;
      let cEvents = [...state.communityEvents];
      cEvents = cEvents.filter(
        (item) => item.event_id != action.payload.eventId
      );

      state.communityEvents = cEvents;
      state.currentEvent = {};
      state.error = null;
      state.isLoading = false;
    },
    removeEventFailure(state, action) {
      state.deleteEventFailure = true;
      state.deleteEventSuccess = false;
      state.error = getError(action);
      state.isLoading = false;
    },

    confirmAttendeeSuccess(state, action) {
      state.confirmedAttendeeSuccess = true;
      state.confirmedAttendeesFailure = false;

      let cEvents = { ...state.currentEvent };
      if (action.payload.action === 'Added') {
        cEvents['event_attendees'].unshift(action.payload.user);
      } else if (action.payload.action === 'Removed') {
        cEvents['event_attendees'] = cEvents['event_attendees'].filter(
          (item) => item.user_id != action.payload.user.user_id
        );
      }
      state.currentEvent = cEvents;

      let ceEvent = [...state.communityEvents];
      ceEvent.forEach((event) => {
        if (event.event_id === action.payload.eventId) {
          if (action.payload.action === 'Added') {
            event['event_attendees'].unshift(action.payload.user);
          } else if (action.payload.action === 'Removed') {
            event['event_attendees'] = event['event_attendees'].filter(
              (item) => item.user_id != action.payload.user.user_id
            );
          }
        }
      });

      state.communityEvents = ceEvent;
      state.error = null;
      state.buttonLoading = false;
    },
    confirmAttendeeFailure(state, action) {
      state.confirmedAttendeeSuccess = false;
      state.confirmedAttendeesFailure = true;
      state.error = getError(action);
      state.buttonLoading = false;
    },

    notAttendeeSuccess(state, action) {
      state.nonAttendeeSuccess = true;
      state.nonAttendeeFailure = false;
      state.error = null;

      let cEvents = [...state.communityEvents];
      cEvents.forEach((event) => {
        if (action.payload.action === 'Added') {
          event['event_non_attendees'].unshift(action.payload.user);
        } else if (action.payload.action === 'Removed') {
          event['event_non_attendees'] = event['event_non_attendees'].filter(
            (item) => item.user_id != action.payload.user.user_id
          );
        }
      });

      state.communityEvents = cEvents;
      state.buttonLoading = false;
    },
    notAttendeeFailure(state, action) {
      state.nonAttendeeFailure = true;
      state.nonAttendeeSuccess = false;
      state.error = getError(action);
      state.buttonLoading = false;
    },

    mightAttendeesSuccess(state, action) {
      state.mightAttendeeSuccess = true;
      state.mightAttendeeFailure = false;
      let cEvents = [...state.communityEvents];
      cEvents.forEach((event) => {
        if (action.payload.action === 'Added') {
          event['event_might_attendees'].unshift(action.payload.user);
        } else if (action.payload.action === 'Removed') {
          event['event_might_attendees'] = event[
            'event_might_attendees'
          ].filter((item) => item.user_id != action.payload.user.user_id);
        }
      });
      state.communityEvents = cEvents;
      state.error = null;
      state.buttonLoading = false;
    },
    mightAttendeesFailure(state, action) {
      state.mightAttendeeFailure = true;
      state.mightAttendeeSuccess = false;
      state.error = getError(action);
      state.buttonLoading = false;
    },
    CurrentEventSuccess(state, action) {
      state.currentEvent = action.payload;
      state.getCurrentEventSuccess = true;
      state.getCurrentEventFailure = false;
      state.isLoading = false;
    },
    CurrentEventFailure(state, action) {
      state.currentEvent = {};
      state.error = getError(action);
      state.getCurrentEventFailure = true;
      state.getCurrentEventSuccess = false;
      state.isLoading = false;
    },
    joinedEventsSuccess(state, action) {
      state.joinedEvents = action.payload;
      state.getJoinedEventSuccess = true;
      state.getJoinedEventFailure = false;
      state.isLoading = false;
      state.error = false;
    },
    joinedEventsFailure(state, action) {
      state.joinedEvents = [];
      state.error = getError(action);
      state.getJoinedEventFailure = true;
      state.getJoinedEventSuccess = false;
      state.isLoading = false;
    },

    emailInviteSuc(state, action) {
      state.emailInviteSuccess = true;
      state.emailInviteFailure = false;
      state.error = null;
      state.buttonLoading = false;
    },
    emailInviteFail(state, action) {
      state.emailInviteSuccess = false;
      state.emailInviteFailure = true;
      state.error = getError(action);
      state.buttonLoading = false;
    },

    clearErrors(state) {
      state.isLoading = false;
      state.buttonLoading = false;
      state.createEventFailure = false;
      state.createEventSuccess = false;
      state.error = null;
      state.fetchEventFailure = false;
      state.fetchEventSuccess = false;
      state.updateEventFailure = false;
      state.updateEventSuccess = false;
      state.deleteEventFailure = false;
      state.deleteEventSuccess = false;
      state.confirmedAttendeeSuccess = false;
      state.confirmedAttendeesFailure = false;
      state.nonAttendeeFailure = false;
      state.nonAttendeeSuccess = false;
      state.mightAttendeeFailure = false;
      state.mightAttendeeSuccess = false;
      state.getCurrentEventSuccess = false;
      state.getCurrentEventFailure = false;
      state.getJoinedEventSuccess = false;
      state.getJoinedEventFailure = false;
      state.emailInviteFailure = false;
      state.emailInviteSuccess = false;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCommunityEvents(communityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/events/get-community-events/${communityId}?join=false`)
      .then((response) => {
        dispatch(slice.actions.getEventSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.getEventFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------
export function getJoinedEvents(communityId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/events/get-community-events/${communityId}?join=true`)
      .then((response) => {
        dispatch(slice.actions.joinedEventsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.joinedEventsFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function postEvent(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .post(`/events/create-event`, { data })
      .then((response) => {
        dispatch(slice.actions.postEventSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.postEventFailure(error));
      });
  };
}

// ----------------------------------------------------------------------

export function updateEvent(data) {
  return async (dispatch) => {
    const event_id = data['event_id'];
    dispatch(slice.actions.startLoading());
    client
      .put(`/events/update-event/${event_id}`, { data })
      .then((response) => {
        dispatch(slice.actions.putEventSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.putEventFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function deleteEvent(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .del(`/events/delete-event/${eventId}`)
      .then((response) => {
        dispatch(slice.actions.removeEventSuccess({ eventId }));
      })
      .catch((error) => {
        dispatch(slice.actions.removeEventFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function confirmAttendee(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    client
      .put(`/events/event/add-remove-attendee/${eventId}`)
      .then((response) => {
        const { user, action } = response.data;
        dispatch(
          slice.actions.confirmAttendeeSuccess({ user, action, eventId })
        );
      })
      .catch((error) => {
        dispatch(slice.actions.confirmAttendeeFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function nonAttendee(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    client
      .put(`/events/event/add-remove-non-attendee/${eventId}`)
      .then((response) => {
        dispatch(slice.actions.notAttendeeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.notAttendeeFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------

export function mightAttendee(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    client
      .put(`/events/event/add-remove-might-attendee/${eventId}`)
      .then((response) => {
        dispatch(slice.actions.mightAttendeesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.mightAttendeesFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------
export function fetchCurrentEvent(event_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    client
      .get(`/events/get-event/${event_id}`)
      .then((response) => {
        dispatch(slice.actions.CurrentEventSuccess(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.CurrentEventFailure({ error }));
      });
  };
}

// ----------------------------------------------------------------------
export function sendEventInvite(eventId, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startButtonLoading());
    client
      .post(`/events/event/send-event-invite/${eventId}`, { data })
      .then((response) => {
        dispatch(slice.actions.emailInviteSuc(response.data));
      })
      .catch((error) => {
        dispatch(slice.actions.emailInviteFail({ error }));
      });
  };
}

// ----------------------------------------------------------------------
export function clearErrors() {
  return async (dispatch) => {
    dispatch(slice.actions.clearErrors());
  };
}
