import { useDispatch, useSelector } from 'react-redux';
import {
  clearAnnoucementResponse,
  clearBlogError,
  clearPostResponse,
  createAnnouncement,
  createBlog,
  createBlogComment,
  createComment,
  createPost,
  deleteBlog,
  deleteBlogComment,
  deleteComment,
  deletePost,
  editBlog,
  editComment,
  editPost,
  feedEnded,
  getAllCommunities,
  getBlog,
  getCommunityDetails,
  handleLogout,
  managerActions,
  setBlog,
  setCurrentTab,
  updateBlogComment,
  updateBlogCommentLikes,
  updateBlogLikes,
  updateCommentLikes,
  updateLike,
  updatePaginatedFeed,
} from '../redux/slices/community';

export default function useCommunity() {
  const {
    isLoading,
    error,
    buttonLoading,
    currentCommunity,
    communityMembers,
    communityBlogs,
    communityProjects,
    communityUpdates,
    communityManagers,
    incbUpdates,
    allCommunities,
    totalCommunityMembers,
    currentTab,
    announcementError,
    createAnnouncementSuccess,
    postError,
    createPostSuccess,
    editPostSuccess,
    deletePostSuccess,
    communityPosts,
    feedEnd,
    blog,
    fetchBlogSuccess,
    fetchBlogFailed,
    createBlogSuccess,
    createBlogFailed,
    updateBlogSuccess,
    updateBlogFailed,
    deleteBlogSuccess,
    deleteBlogFailed,
    blogCommentLikesSuccess,
    blogCommentLikesFailed,
  } = useSelector((state) => state.community);

  const dispatch = useDispatch();

  return {
    isLoading,
    error,
    buttonLoading,
    currentCommunity,
    communityMembers,
    communityBlogs,
    communityProjects,
    communityUpdates,
    communityManagers,
    incbUpdates,
    allCommunities,
    totalCommunityMembers,
    currentTab,
    announcementError,
    createAnnouncementSuccess,
    postError,
    createPostSuccess,
    editPostSuccess,
    deletePostSuccess,
    communityPosts,
    feedEnd,
    blog,
    fetchBlogSuccess,
    fetchBlogFailed,
    createBlogSuccess,
    createBlogFailed,
    updateBlogSuccess,
    updateBlogFailed,
    deleteBlogSuccess,
    deleteBlogFailed,
    blogCommentLikesSuccess,
    blogCommentLikesFailed,

    createAnnouncement: (data) => dispatch(createAnnouncement(data)),
    createPost: (data) => dispatch(createPost(data)),
    editPost: (data) => dispatch(editPost(data)),
    deletePost: (data) => dispatch(deletePost(data)),

    createComment: (data) => dispatch(createComment(data)),
    editComment: (data) => dispatch(editComment(data)),
    deleteComment: (data) => dispatch(deleteComment(data)),

    updateLike: (data) => dispatch(updateLike(data)),
    updateCommentLikes: (data) => dispatch(updateCommentLikes(data)),
    getCommunityDetails: (community) =>
      dispatch(getCommunityDetails(community)),
    getAllCommunities: () => dispatch(getAllCommunities()),
    setCurrentTab: (tab) => dispatch(setCurrentTab(tab)),
    communityHandleLogout: () => dispatch(handleLogout()),
    clearAnnoucementResponse: () => dispatch(clearAnnoucementResponse()),
    clearPostResponse: () => dispatch(clearPostResponse()),
    managerActions: (community, manager, operation) =>
      dispatch(managerActions(community, manager, operation)),
    getBlog: (blog_id) => dispatch(getBlog(blog_id)),
    createBlog: (blog) => dispatch(createBlog(blog)),
    editBlog: (blog) => dispatch(editBlog(blog)),
    deleteBlog: (blog_id) => dispatch(deleteBlog(blog_id)),
    createBlogComment: (data) => dispatch(createBlogComment(data)),
    updateBlogComment: (data) => dispatch(updateBlogComment(data)),
    deleteBlogComment: (data) => dispatch(deleteBlogComment(data)),
    updateBlogLikes: (data, user_id) =>
      dispatch(updateBlogLikes(data, user_id)),
    updateBlogCommentLikes: (data) => dispatch(updateBlogCommentLikes(data)),
    setBlog: (blog) => dispatch(setBlog(blog)),
    clearBlogError: () => dispatch(clearBlogError()),
    updatePaginatedFeed: (loaded_feed) =>
      dispatch(updatePaginatedFeed(loaded_feed)),
    feedEnded: () => dispatch(feedEnded()),
  };
}
