import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ChatIcon from '@material-ui/icons/Chat';
// material
import {
  alpha,
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import useCommunity from '../../hooks/useCommunity';
import { PATH_DASHBOARD } from '../../routes/paths';
import AccountPopover from './AccountPopover';
import ThemeSwitcher from './ThemeSwitcher';
// ----------------------------------------------------------------------

let DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: `${
    theme.palette.mode == 'light'
      ? theme.palette.common.white
      : theme.palette.grey[900]
  }`,
  '& .isDesktopActive': {
    color: `${theme.palette.primary.main} !important`,
  },
  '& .isMobileActive': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  isShrinkSidebar: PropTypes.bool,
};

export default function Navbar({ onOpenSidebar, isShrinkSidebar }) {
  const theme = useTheme();
  const [openMenu, setOpenMenu] = useState({
    right: false,
  });
  DRAWER_WIDTH = isShrinkSidebar ? 100 : 280;
  const { getCommunityDetails, currentCommunity } = useCommunity();
  const { user } = useAuth();

  const { home_community, other_community } = user.user_communities;

  let community = currentCommunity ? currentCommunity : home_community;
  let MENU_LINKS = [
    {
      title: 'Dashboard',
      icon: bookOpenFill,
      href: PATH_DASHBOARD.general.app,
    },
    {
      title: 'Community View',
      href: `${PATH_DASHBOARD.root}/community/${currentCommunity.community_id}`,
    },
    {
      title: 'Help',
      href: PATH_DASHBOARD.general.help,
    },
    // {
    //   title: 'Academy',
    //   href: PATH_DASHBOARD.academy.root,
    // },
  ];

  const { recentMessages } = useSelector((state) => state.chat);

  let totalCounter = 0;

  _.forEach(recentMessages, (m) => {
    if (!m.read && m.recieverId === user.user_id) totalCounter += 1;
  });

  useEffect(() => {
    let s = window.location.pathname.split('/');
    let url_community = s[s.length - 1];
    // if (
    //   url_community &&
    //   (!currentCommunity || currentCommunity.community_id !== url_community)
    // ) {
    //   getCommunityDetails({ community_id: url_community });
    // } else
    if (!currentCommunity && home_community) {
      getCommunityDetails(home_community);
    }
  }, [currentCommunity.community_id]);

  const renderMenuDesktop = (
    <>
      <ThemeSwitcher />
      <Badge badgeContent={totalCounter} color='error'>
        <Link
          exact
          to={PATH_DASHBOARD.chat.root}
          key='Messages'
          underline='none'
          variant='subtitle2'
          component={RouterLink}
          activeClassName='isDesktopActive'
          textOverflow='ellipsis'
          activeStyle={{
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          }}
          sx={{
            ml: 3,
            py: 1,
            color: 'text.primary',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
              }),
            '&:hover': { opacity: 0.8 },
          }}
        >
          <ChatIcon />
        </Link>
      </Badge>
      {MENU_LINKS.map((link) => (
        <Link
          exact
          to={link.href}
          key={link.title}
          underline='none'
          variant='subtitle2'
          component={RouterLink}
          activeClassName='isDesktopActive'
          textOverflow='ellipsis'
          activeStyle={{
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          }}
          sx={{
            ml: 3,
            py: 1,
            color: 'text.primary',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
              }),
            '&:hover': { opacity: 0.8 },
          }}
        >
          {link.title}
        </Link>
      ))}
    </>
  );

  const headerLogo = () => (
    <RouterLink to='/' style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Logo />
        &nbsp;&nbsp;
        <Link
          exact
          to='/'
          underline='none'
          variant='h3'
          activeClassName='isDesktopActive'
          sx={{
            fontWeight: 'bolder',
            mt: 1,
            mr: 5,
            color: 'text.primary',
            transition: (theme) =>
              theme.transitions.create('opacity', {
                duration: theme.transitions.duration.shortest,
              }),
            '&:hover': { opacity: 0.6 },
            // ...(offset && { color: 'text.primary' }),
            lineHeight: 1,
          }}
        >
          <p>IncBuilders</p>
          <Typography
            sx={{ p: 0, m: 0, display: { xs: 'none', sm: 'inherit' } }}
            variant='body2'
          >
            Social Media for Entrepreneurship
          </Typography>
        </Link>
      </Box>
    </RouterLink>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenMenu({ ...openMenu, [anchor]: open });
  };

  const list = (anchor, theme) => (
    <Box role='presentation' sx={{ width: '22em' }}>
      {headerLogo()}
      <Divider />
      {/* <ThemeSwitcher /> */}
      <List
        fullWidth
        disablePadding
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <Badge
          variant='dot'
          badgeContent={totalCounter}
          overlap='circular'
          color='error'
        >
          <Button
            fullWidth
            component={RouterLink}
            key='Messages'
            to={PATH_DASHBOARD.chat.root}
            sx={{
              color: `${
                theme.palette.mode == 'light' ? 'common.black' : 'common.white'
              }`,
            }}
          >
            <ListItem disablePadding>
              <ListItemText primary='Messages' />
            </ListItem>
          </Button>
        </Badge>
        {MENU_LINKS.map((menu) => (
          <Button
            fullWidth
            component={RouterLink}
            key={menu.title}
            to={menu.href}
            sx={{
              color: `${
                theme.palette.mode == 'light' ? 'common.black' : 'common.white'
              }`,
            }}
          >
            <ListItem disablePadding>
              <ListItemText primary={menu.title} />
            </ListItem>
          </Button>
        ))}
      </List>
    </Box>
  );

  return (
    <RootStyle>
      <Container sx={{ minWidth: '80%' }}>
        <ToolbarStyle style={{ margin: 0, padding: 0 }}>
          <Hidden mdDown>
            {headerLogo()}
            {/* <Search /> */}
          </Hidden>
          <Hidden mdUp>
            <Logo height={45} />
            <IconButton sx={{ ml: 1 }}>
              <SearchOutlinedIcon />
            </IconButton>
          </Hidden>
          <Box sx={{ flexGrow: 1 }} />
          <Hidden mdDown>{renderMenuDesktop}</Hidden>
          {/* <NotificationsPopover sx={{ml: 1}} /> */}
          <Hidden mdUp>
            <ThemeSwitcher />
          </Hidden>

          <AccountPopover sx={{ ml: { xs: 0, sm: 2 } }} />

          <Hidden smDown mdUp>
            <>
              <MenuIcon
                onClick={toggleDrawer('right', true)}
                sx={{ color: 'text.primary', height: '3em', ml: 1 }}
              />
              <Drawer
                anchor='right'
                open={openMenu['right']}
                onClose={toggleDrawer('right', false)}
              >
                {list('right', theme)}
              </Drawer>
            </>
          </Hidden>
        </ToolbarStyle>
      </Container>
    </RootStyle>
  );
}
