import lockFill from "@iconify/icons-eva/lock-fill";
import personFill from "@iconify/icons-eva/person-fill";
import {Icon} from "@iconify/react";
import {Box, Button, Divider, MenuItem, Typography} from "@material-ui/core";
// material
import {alpha} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";
import {useRef, useState} from "react";
import {Link as RouterLink, useHistory} from "react-router-dom";
// components
import {MIconButton} from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import MyAvatar from "../../components/MyAvatar";
// hooks
import useAuth from "../../hooks/useAuth";
import useCommunity from "../../hooks/useCommunity";
import useIsMountedRef from "../../hooks/useIsMountedRef";
// routes
import {PATH_DASHBOARD} from "../../routes/paths";

// ----------------------------------------------------------------------

let MENU_OPTIONS = [
  {
    label: "Home",
    icon: personFill,
    linkTo: "/",
  },
  {
    label: "View Profile",
    icon: personFill,
    linkTo: PATH_DASHBOARD.general.app,
  },
  // {
  //   label: "Profile",
  //   icon: personFill,
  //   linkTo: PATH_DASHBOARD.user.profile,
  // },
  // {
  //   label: 'Settings',
  //   icon: settings2Fill,
  //   linkTo: PATH_DASHBOARD.user.account,
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover({...others}) {
  const history = useHistory();
  const anchorRef = useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const {user, logout} = useAuth();
  const [open, setOpen] = useState(false);
  const {communityHandleLogout} = useCommunity();

  // if (user.oauth === false) {
  //   MENU_OPTIONS.push({
  //     label: "Change Password",
  //     icon: settings2Fill,
  //     linkTo: PATH_DASHBOARD.user.settings,
  //   });
  // }

  if (!user.oauth) {
    MENU_OPTIONS = [
      {
        label: "View Profile",
        icon: personFill,
        linkTo: PATH_DASHBOARD.general.app,
      },
      // {
      //   label: 'Profile',
      //   icon: personFill,
      //   linkTo: PATH_DASHBOARD.user.profile,
      // },
      {
        label: "Change Password",
        icon: lockFill,
        linkTo: PATH_DASHBOARD.user.settings,
      },
    ];
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      await communityHandleLogout();
      if (isMountedRef.current) {
        history.push("/auth/login");
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout", {variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center' }});
    }
  };

  if (!user) {
    handleLogout();
    return;
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          marginRight: 1.5,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
        {...others}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 220}}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle1" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
            {user.user_email}
          </Typography>
        </Box>

        <Divider sx={{my: 1}} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{typography: "body2", py: 1, px: 2.5}}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{p: 2, pt: 1.5}}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
