import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('../views/LandingPage')),
    },
    // {
    //   exact: true,
    //   path: '/components',
    //   component: lazy(() => import('../views/ComponentsOverview')),
    // },
    {
      exact: true,
      path: '/startabusiness',
      component: lazy(() => import('../views/Entrepreneur')),
    },
    {
      exact: true,
      path: '/helpabusiness',
      component: lazy(() => import('../views/LandingPageHelpABusiness')),
    },
    {
      exact: true,
      path: '/social_login',
      component: lazy(() =>
        import('../components/authentication/SocialLoginModal')
      ),
    },
    {
      exact: true,
      path: '/gallery',
      component: lazy(() => import('../views/Gallery')),
    },
    {
      exact: true,
      path: '/about-us',
      component: lazy(() => import('../views/AboutUs')),
    },
    {
      exact: true,
      path: '/feed/:feed_id',
      component: lazy(() => import('../views/ViewFeed')),
    },
    {
      exact: true,
      path: '/user/:user_id',
      component: lazy(() => import('../views/ViewReportedUser')),
    },
    {
      exact: true,
      path: '/contact-us',
      component: lazy(() => import('../views/ContactUs')),
    },
    {
      exact: true,
      path: '/pricing',
      component: lazy(() => import('../views/Pricing')),
    },
    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to='/404' />,
    },
  ],
};

export default HomeRoutes;
