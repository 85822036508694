// import { Link as ScrollLink } from 'react-scroll';
// material
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles';
import {
  FiFacebook,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiYoutube,
} from 'react-icons/fi';
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi';
// import FacebookOutlinedIcon from "@material-ui/icons/FacebookOutlined"
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from '../../routes/paths';
// import { IoCallOutline } from 'react-icons/io5';
import VideoDialog from '../../components/general/VideoDialog';
import Logo from '../../components/Logo';
import FooterMap from './FooterMap';
import FacebookOutlined from '@material-ui/icons/FacebookOutlined';
import {
  Instagram,
  LinkedIn,
  Twitter,
  YouTube,
  MailRounded,
  LocationOn,
} from '@material-ui/icons';

// routes

//
// import { varFadeInDown, varFadeInUp, MotionInView } from '../animate';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: `${theme.palette.grey[500_24]}`,

  height: 'auto',
  width: '100%',

  paddingTop: '2em',
  paddingBottom: '2em',
}));

const Footer_Links = [
  // { title: 'Whats IncB', href: PATH_PAGE.comingSoon },
  { title: 'Gallery', href: PATH_PAGE.gallery },
  { title: 'Pricing', href: PATH_PAGE.pricing },
  { title: 'About us', href: PATH_PAGE.aboutUs },
  { title: 'Contact us', href: PATH_PAGE.contactUs },
];

export default function Footer() {
  const theme = useTheme();
  const iconColor =
    theme.palette.mode == 'light'
      ? theme.palette.grey[900]
      : theme.palette.common.white;
  const SOCIALS = [
    {
      name: 'Twitter',
      icon: <Twitter sx={{ fontSize: 30, color: iconColor }} />,
      link: 'https://twitter.com/incbuildersclub',
    },
    {
      name: 'FaceBook',
      icon: <FacebookOutlined sx={{ fontSize: 30, color: iconColor }} />,
      link: 'https://www.facebook.com/incbuilders',
    },
    {
      name: 'Instagram',
      icon: <Instagram sx={{ fontSize: 30, color: iconColor }} />,
      link: 'https://www.instagram.com/incbuildersclub',
    },

    {
      name: 'LinkedIn',
      icon: <LinkedIn sx={{ fontSize: 30, color: iconColor }} />,
      link: 'https://www.linkedin.com/company/16219850',
    },

    {
      name: 'YouTube',
      icon: <YouTube sx={{ fontSize: 30, color: iconColor }} />,
      link: 'https://www.youtube.com/channel/UCo21n8COUNacWkYU_FYTEaA',
    },
  ];

  const background = theme.palette.mode === 'light' ? '#EAEAEA' : '';

  return (
    <ContentStyle>
      <Container sx={{ minWidth: '80%' }}>
        <Grid container xs={12}>
          <Grid
            item
            md={6}
            xs={12}
            container
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <RouterLink to='/' style={{ textDecoration: 'none' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Logo />
                &nbsp;&nbsp;
                <Link
                  exact
                  to='/'
                  underline='none'
                  variant='h3'
                  activeClassName='isDesktopActive'
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'bolder',
                  }}
                >
                  IncBuilders
                </Link>
              </Box>
            </RouterLink>
          </Grid>
          <Grid
            container
            item
            md={6}
            xs={12}
            sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
          >
            <Box sx={{ textAlign: 'center', p: 0, m: 0 }}>
              {SOCIALS.map((social) => (
                <Tooltip key={social.name} title={social.name}>
                  <a href={social.link} target='_blank'>
                    <IconButton>{social.icon}</IconButton>
                  </a>
                </Tooltip>
              ))}
            </Box>
          </Grid>
          <Grid
            container
            item
            md={12}
            xs={12}
            sx={{
              mb: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item sx={{ width: 'fit-content', px: 1 }}>
              <VideoDialog
                buttonName="What's IncB"
                title='Introduction'
                url='QIkHg7_YKOQ'
                buttonsx={{ fontSize: '1em' }}
              />
            </Grid>

            {Footer_Links.map((link) => {
              return (
                <Grid
                  item
                  key={link.title}
                  style={{ textAlign: 'left' }}
                  sx={{
                    borderLeft: '2px solid',
                    width: 'fit-content',
                    px: 1,
                  }}
                >
                  <Button
                    to={link.href}
                    key={link.title}
                    variant='subtitle1'
                    component={RouterLink}
                    sx={{ fontSize: '1em' }}
                  >
                    {link.title}
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ mt: 3 }}
        >
          <Typography variant='body1' textAlign='center'>
            <span style={{ fontSize: '20px' }}>&copy;</span> 2017-
            {new Date().getFullYear()} by IncBuilders LLC | All rights reserved
          </Typography>
        </Grid>
      </Container>
    </ContentStyle>
  );
}
